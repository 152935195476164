<template>
  <div class="map-buttons">
    <a-button @click="removeWhileMove=!removeWhileMove" :style="removeWhileMove ? 'background-color:#0067bd':''">{{$t(removeWhileMove ? 'addMode': 'removeMode')}} (SHIFT)</a-button>
    <a-button  @click="myMouseMoveMode=!myMouseMoveMode" :style="myMouseMoveMode ? 'background-color:#0067bd':''">{{$t(myMouseMoveMode ? 'clickMode': 'moveMode')}} (CTRL)</a-button>
    <a-button :loading="loading" @click="save" type="primary">{{$t('save')}}</a-button>
  </div>
  <div style="height: 600px;margin-top: 40px;" id="map"></div>
</template>

<script>


import L from 'leaflet'
import geoJson from '@/assets/5digit.json'
import {get, post} from "../../helper/api";
import {displaySuccessMessage} from "../../helper/messages";

export default {
  name: "map",
  data() {
    return {
      loading:false,
      myMouseMoveMode: false,
      removeWhileMove: false,
      activePostCodes: [],
      activePostCodesText: [],
    }
  },
  async mounted() {

    const map = L.map('map').setView([51.376331, 10.208508], 6)

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,

    }).addTo(map);


    document.addEventListener('keydown', this.keyPressed);


    let postCodes = this.$store.configStore.user.config.postCodes
    this.activePostCodesText = this.$store.configStore.user.config.postCodesText
    if(postCodes == null || (postCodes.length === 1 && postCodes[0]==="")){
      postCodes = []
    }

    if(this.activePostCodesText == null || (this.activePostCodesText.length === 1 && this.activePostCodesText[0]==="")){
      this.activePostCodesText = []
    }


    this.activePostCodes = postCodes

    setTimeout(()=>{
      L.geoJSON(geoJson, {
        style: this.geoStyle,
        onEachFeature: this.onEachFeature
      }).addTo(map)
    }, 2000);



  },
  methods: {
    geoStyle(feature) {

      if (this.activePostCodes.indexOf(feature.properties.plz) > -1) {

        return {color: "green", weight: 1}

      } else {
        return {color: "#fa82317d", weight: 1}
      }
    },
    mouseMove(layer) {

      if (this.myMouseMoveMode) {
        if (this.removeWhileMove) {
          this.makeBlue(layer)

        } else {
          this.makeGreen(layer)
        }


      }


    },
    layerClick(layer) {
      if (!this.myMouseMoveMode) {
        if (layer.options.color !== "green") {
          console.log(layer.feature.properties.plz)
          this.makeGreen(layer)
          return true
        }

        if (layer.options.color === "green") {

          this.makeBlue(layer)
        }


      }


    },
    onEachFeature(feature, layer) {

      layer.on('mousemove',()=>{
        this.mouseMove(layer)
      })
      layer.on('click', ()=>{
        this.layerClick(layer)
      })
    },
    makeGreen(layer) {
      this.activePostCodesText.push(layer.feature.properties.note)

      this.addActivePostCode(layer.feature.properties.plz)
      layer.setStyle({color: "green"})

    },

    makeBlue(layer) {
      const indexText = this.activePostCodesText.indexOf(layer.feature.properties.note)
      if(indexText !== -1){
        this.activePostCodesText.splice(indexText, 1);
      }


      this.removeActivePostCode(layer.feature.properties.plz);
      layer.setStyle({color: "#fa82317d"})

    },

    keyPressed(event) {

      if (event.key === 'Control') {
        this.myMouseMoveMode = !this.myMouseMoveMode
        console.log( this.myMouseMoveMode)
      }
      if (event.key === 'Shift') {
        this.removeWhileMove = !this.removeWhileMove
        console.log( this.myMouseMoveMode)
      }

    },
    addActivePostCode(code) {
      const index = this.activePostCodes.indexOf(code)
      if (index === -1) {
        this.activePostCodes.push(code)
      }
    },
    removeActivePostCode(code) {
      const index = this.activePostCodes.indexOf(code)

      this.activePostCodes.splice(index, 1);
    },
    toggleMouseMoveMode() {
      this.myMouseMoveMode = !this.myMouseMoveMode
      document.dispatchEvent(
          new KeyboardEvent("keydown", {
            ctrlKey: true
          })
      );
    },
    async save() {
      this.loading=true
      console.log("save")
      console.log(this.activePostCodes)
      await post('config',{postCodes:this.activePostCodes,postCodesText:this.activePostCodesText})
      this.loading=false
      displaySuccessMessage()
    }

  }

}
</script>

<style lang="sass">
@import 'leaflet/dist/leaflet.css'
.map-buttons
  display: flex
  flex-direction: row
  justify-content: end
  button
    margin: 0 0 0 8px
  @media (max-width: 992px)
    flex-direction: column
    justify-content: center
    button
      margin: 0 0 8px 0

</style>