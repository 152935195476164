<template>
  <crud-header title="shopSettings"></crud-header>
  <a-tabs  :tab-bar-style="{'padding-left':'14px','padding-right':'14px'}"
          v-model:activeKey="activeKey">
    <a-tab-pane key="0">
      <template #tab>
        <span>
          <shop-outlined/>
           {{$t('shop')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
        <Settings_form/>
      </div>


    </a-tab-pane>
    <a-tab-pane key="1">
      <template #tab>
        <span>
          <shopping-outlined/>
           {{$t('order')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <order_page/>
      </div>

    </a-tab-pane>
    <a-tab-pane key="2">
      <template #tab>
        <span>
          <bell-outlined/>
           {{$t('notifications')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <settings_onesignal_form/>
      </div>

    </a-tab-pane>
    <a-tab-pane key="3">
      <template #tab>
        <span>
          <mail-outlined/>
           {{$t('contact')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <Settings_contact_form/>
      </div>
    </a-tab-pane>
    <a-tab-pane key="4">
      <template #tab>
        <span>
          <file-protect-outlined/>
           {{$t('terms')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <Settings_document_form type="terms"/>
      </div>
    </a-tab-pane>
    <a-tab-pane key="5">
      <template #tab>
        <span>
         <file-protect-outlined/>
           {{$t('privacy')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <Settings_document_form type="privacyPolicy"/>
      </div>
    </a-tab-pane>
    <a-tab-pane key="6">
      <template #tab>
        <span>
          <file-protect-outlined/>
           {{$t('legal')}}
        </span>
      </template>
      <div class="pl-3 pr-3">
      <Settings_document_form type="legalNotice"/>
      </div>
    </a-tab-pane>



  </a-tabs>

</template>

<script>

import {MailOutlined,FileDoneOutlined,FileProtectOutlined,ShoppingOutlined,ShopOutlined,BellOutlined} from "@ant-design/icons-vue";

import Settings_form from "../../components/dashboard/forms/settings_form.vue";
import Settings_contact_form from "../../components/dashboard/forms/settings_contact_form.vue";
import CrudHeader from '@/components/dashboard/crud/header.vue'
import Settings_document_form from "../../components/dashboard/forms/settings_document_form.vue";
import order_page from "@/components/dashboard/order.vue"
import {useMeta} from "vue-meta";
import Settings_onesignal_form from "../../components/dashboard/forms/settings_onesignal_form.vue";
export default {
  name: "Settings",
  components: {
    Settings_onesignal_form,
    BellOutlined,CrudHeader,Settings_contact_form,Settings_document_form, Settings_form,MailOutlined,FileDoneOutlined,order_page,FileProtectOutlined,ShoppingOutlined,ShopOutlined},
  data(){
    return {
      activeKey:"0"
    }
  },
  mounted() {
    useMeta({
      title: this.$t('settings')
    })
  }
}
</script>

<style scoped>

</style>