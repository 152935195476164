<template>


  <a-modal
      v-model:visible="showProductStore.showFormModal"
      centered
      :footer="false"
  >
    <div class="p-2">


    <div class="images">
      <img :src="'https://cdn.bringsdelivery.de/'+product.thumbnail">
      <img v-for="thumbnail in product.images" :src="'https://cdn.bringsdelivery.de/'+thumbnail">


    </div>
      <p class="product-title">
        {{product.title}}
      </p>
      <p v-html="product.description">

      </p>
    </div>
  </a-modal>

</template>

<script>

import {ShowProductStore} from "../../../stores/modal";


export default {
  name: "show_product_modal",
  props:{
    product:Object
  },
  data(){
    return{
      showProductStore: {}
    }

  },
  mounted() {
    this.showProductStore = ShowProductStore()
  }

}
</script>

<style lang="sass" scoped>
.images
  display: flex
  flex-direction: row
  overflow: auto
  height: 200px
  img
    margin-right: 12px
.product-title
  margin: 12px 0 12px 0
  font-size: 15px
  font-weight: 500

</style>