<template>






    <a-table
        class="p-3"
        v-if="visible"
        :scroll="{ x: 'max-content' }"
        expand-row-by-click
        style="width: 100%"

        :columns="columns"
        :row-key="order => order.id"
        :data-source="$store.userStore.items"
        :pagination="pagination"
        :loading="$store.userStore.loading"
        @change="tableStateChanged"
    >
      <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">

          <a-input
              ref="searchInput"
              :placeholder="$t('search')+` ${column.dataIndex}`"

              v-model:value="searchText[column.dataIndex]"
              style="width: 215px; margin-bottom: 8px; display: block"
              @pressEnter="search(column.dataIndex)"
          />
          <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="search(column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined/>
            </template>
            {{$t('search')}}
          </a-button>
          <a-button size="small" style="width: 120px" @click="resetSearch(column.dataIndex)">
            {{$t('reset')}}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered,column }">

        <search-outlined  :style="{ color: hasFilter[column.dataIndex] ? '#108ee9' : undefined }"/>
      </template>
      <template #buildOptionText="props">
        <span>{{ props.value }} / {{$t('result')}}</span>
      </template>

      <template #expandedRowRender="{record}">
        <user-details :user="record" :getItems="getItems"/>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'created'">
          <span>
             {{ parseDate(record.created) }}
          </span>
        </template>
        <template v-if="column.dataIndex === 'email'">
          <span>
            <check-circle-outlined v-if="record.emailVerified" style="color:green"/>
            <close-circle-outlined v-else style="color:red"/>
             {{record.email}}
          </span>
        </template>




      </template>
    </a-table>




</template>

<script>


import SingleItem from "@/components/dashboard/crud/product/single.vue"
import UserDetails from "./detail.vue";




import {

  EyeOutlined,
  PrinterOutlined,
  ReloadOutlined,
  DownOutlined,
  UpOutlined,
    FileDoneOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,FilterOutlined
} from "@ant-design/icons-vue"
import dayjs from "dayjs";
import {post} from "../../../../helper/api";



const sleep = m => new Promise(r => setTimeout(r, m))


export default {


  components: {
    UserDetails,
    SingleItem,
    ReloadOutlined,
    PrinterOutlined,
    EyeOutlined,
    DownOutlined,
    UpOutlined,
    FileDoneOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined,FilterOutlined,

  },

  async mounted() {
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 'width: 80px',
      },

      {
        title: this.$t('registerDate'),
        dataIndex: 'created',
        sorter: true,
        width: 'width: 100px',
      },
      {
        title: this.$t('name'),
        dataIndex: 'name',
        width: 'width: 100px',
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.$refs.searchInput.input.focus();
            }, 100);
          }
        },
      },
      {
        title: this.$t('lastname'),
        dataIndex: 'lastname',
        width: 'width: 100px',
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.$refs.searchInput.input.focus();
            }, 100);
          }
        },
      },
      {
        title: this.$t('email'),
        dataIndex: 'email',
        width: 'width: 100px',
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.$refs.searchInput.input.focus();
            }, 100);
          }
        },
      }

    ]


    await this.checkSavedTableState()

  },
  data() {
    return {

      clickedOrder:null,
      loadingStatus:{},
      datePickerValue: null,
      visible: false,
      activeOrderId: 0,

      orderStatus: "all",
      loading: false,
      items: [],
      pagination: {

        pageSize: 20

      },
      orderCounts:null,
      columns: [],
      tableState: {
        pagination: null, filters: null, sorter: null
      },
      searchText:{name:'',lastname:'',email:''},
      hasFilter:{},
    }

  },


  methods: {

    search(key) {
      console.log(this.searchText)
      this.hasFilter[key] = true
      this.tableState[key] = this.searchText[key]
      this.getItems()

    },
    resetSearch(key) {
      this.hasFilter[key] = false
      delete this.tableState[key]
      this.searchText[key] = ""

      this.getItems()
    },

    async print(order) {
      console.log(order)
      this.printOrder = order
      await sleep(1000)
      console.log(this.$refs.bill)
      let divContents = this.$refs.bill.innerHTML;
      let a = window.open('', '', 'height=500, width=500');
      a.document.write('<html>');
      a.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css">');

      a.document.write(divContents);
      a.document.write('</body></html>');

      // setTimeout(function(){a.print();a.close()},1000);
    },
    async tabChanged(value) {
      this.tableState.page = 1
      console.log(this.tableState)

      if (value !== 'all') {
        this.tableState.status = value
        await this.changeTableStatus(value)

      } else {
        delete this.tableState.status
        await this.changeTableStatus()
      }
      await this.getItems()

    },
    async changeTableStatus(status) {
      this.visible = false
      await sleep(1)
      for (let k = 0; k < this.columns.length; k++) {
        if (this.columns[k].dataIndex === 'status') {

          console.log('sil')
          if (status) {
            this.columns[k]['defaultFilteredValue'] = [status]
          } else {
            delete this.columns[k]['defaultFilteredValue']
          }

        }
      }
      console.log(this.columns)
      this.visible = true
    },

    async checkSavedTableState() {
      let savedTableState = localStorage.getItem('tableStateUser')
      let dateRange = localStorage.getItem('dateRange')


      if (dateRange) {

        let json = Object.values(JSON.parse(dateRange))
        let dates = []
        json.forEach((i) => {
          dates.push(dayjs(i))
        })
        this.datePickerValue = dates
      }
      if (savedTableState) {
        this.tableState = JSON.parse(savedTableState)
        console.log(this.tableState)
        if (this.tableState.page) {
          this.pagination.defaultCurrent = this.tableState.page

        }

      } else {
        this.tableState = {
          pagination: null, filters: null, sorter: null
        }
      }


      for (let k = 0; k < this.columns.length; k++) {
        if (this.columns[k].dataIndex === 'storeTable') {
          this.columns[k]['filters'] = this.$store.tableStore.items.map((table) => {
            return {text: table.name, value: table.key.toString()}
          })
          if (this.tableState.table) {
            this.columns[k]['defaultFilteredValue'] = Object.values(this.tableState.table).map((i) => i.toString())
          }

        }
        if (this.columns[k].dataIndex === 'status') {

          if (this.tableState.status) {

            this.columns[k]['defaultFilteredValue'] = Object.values(this.tableState.status)

          }
        }

        if (this.columns[k].dataIndex === 'updated') {
          if (this.tableState.sorter) {
            this.columns[k]['defaultSortOrder'] = this.tableState.sorter

          }


        }
      }


      await sleep(1)
      this.visible = true
      this.getItems()
    },
    resetFilters() {
      this.visible = false
      localStorage.removeItem('tableStateUser')
      this.checkSavedTableState()

    },
    tableStateChanged(pagination, filters, sorter) {
      console.log(pagination)
      console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
      }
      if (filters) {
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['storeTable']) {
          filter['table'] = filters['storeTable']
        }
      }
      if (sorter && sorter.field && sorter.order) {
        filter['sorter'] = sorter.order
      }
      this.tableState = filter
      localStorage.setItem('tableStateUser', JSON.stringify(this.tableState))
      this.getItems()
    },
    onRangeOk(date1) {
      console.log(this.datePickerValue)

      if (date1 == null) {
        localStorage.removeItem('dateRange')
        console.log('get items')
        this.getItems()
        return;
      }
      // this.firstDate = date1[0].format('YYYY-MM-DD HH:mm:ss')
      // this.secondDate = date1[1].format('YYYY-MM-DD HH:mm:ss')
      localStorage.setItem('dateRange', JSON.stringify(date1))
      this.getItems()
    },
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },



    async getItems() {

      let filter = this.tableState




      await this.$store.userStore.getItems(filter)

      this.pagination.total = this.$store.userStore.result.json.totalItemCount

    }
  }


}
</script>

<style lang="sass">


.ant-pagination-options
  display: none
.index-middle
  margin: 12px 24px

.ant-table-cell
  vertical-align: middle


</style>