<template>



    <a-table
        class="pl-3 pr-3"
        :scroll="{ x: 'max-content' }"
        expandRowByClick
        style="width: 100%"
        :row-key="data => data.id"
        :columns="columns"
        :data-source="$store.brandStore.items"
        :pagination="pagination"
        :loading="$store.brandStore.loading"
        @change="tableStateChanged"
    >
      <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <a-input
              ref="searchInput"
              :placeholder="$t('search')+` ${column.dataIndex}`"
              :value="selectedKeys[0]"
              v-model:value="searchText"
              style="width: 188px; margin-bottom: 8px; display: block"
              @pressEnter="search"
          />
          <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="search"
          >
            <template #icon>
              <SearchOutlined/>
            </template>
            {{$t('search')}}
          </a-button>
          <a-button size="small" style="width: 90px" @click="resetSearch">
            {{$t('reset')}}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered,column }">

        <search-outlined  :style="{ color: searchFilter ? '#108ee9' : undefined }"/>

      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'active'">
          <a-switch @change="updateActive(record.id,record.active)" size="small" v-model:checked="record.active">
          </a-switch>
        </template>
        <template v-if="column.dataIndex === 'price'">
      <span>
        {{ (record.price / 100).toFixed(2) + ' ' + this.$store.configStore.user.currency }}
      </span>
        </template>
        <template v-if="column.dataIndex === 'thumbnail'">

          <img v-if="record.thumbnail" height="40" width="40" :src="record.thumbnail ? 'https://cdn.bringsdelivery.de/'+record.thumbnail:''">
        </template>
        <template v-if="column.dataIndex === undefined">
          <a-button @click.stop="$store.brandFormStore.openWithData(record)" type="text">
            <template #icon>
              <edit-outlined/>
            </template>
          </a-button>
          <a-popconfirm :title="$t('deleteConfirm')"
                        @confirm="deleteProduct(record.id)">
            <a-button @click.stop type="text">
              <template #icon>
                <DeleteOutlined/>
              </template>
            </a-button>

          </a-popconfirm>
        </template>
      </template>

    </a-table>

</template>
<script>


import SingleItem from "@/components/dashboard/crud/product/single.vue";

import {SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined} from "@ant-design/icons-vue";
import {post} from "@/helper/api";


export default {


  components: {SingleItem, SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined},
  data() {
    return {
      columns: [],
      pagination: {
        pageSize: 20
      },
      searchText: "",
      loadingDelete: false,
      searchFilter: false

    }
  },

  mounted() {

    this.columns = [

      {
        title: this.$t('image'),
        dataIndex: 'thumbnail',
        width: '170px'
      },


      {
        title: this.$t('brand'),
        dataIndex: 'name',
        customFilterDropdown: true,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.$refs.searchInput.input.focus();
            }, 100);
          }
        },
      },


      {
        title: '',
        width: '140px'
      },

    ]
    this.getItems()


  },
  methods: {
    async updateActive(id, active) {
      await post('product', {
        'id': id,
        'active': active
      })
    },
    async deleteProduct(id) {
      this.loadingDelete = true
      await post('product/delete', {
        'id': id
      })
      this.loadingDelete = false
      await this.getItems()

    },
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },
    async getItems() {
      await this.$store.brandStore.getItems(this.tableState)
      //console.log(this.$store.brandStore.result)
      this.pagination.total = this.$store.brandStore.result.json.totalItemCount
    },
    tableStateChanged(pagination, filters, sorter) {
      //console.log(pagination)
      //console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
      }
      if (filters) {
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['stock']) {
          filter['stock'] = filters['stock']
        }
      }
      if (sorter && sorter.field && sorter.order) {
        filter['sorter'] = sorter.order
      }
      this.tableState = filter
      localStorage.setItem('tableState', JSON.stringify(this.tableState))
      this.getItems()
    },
    search() {
      this.searchFilter = true
      this.tableState = {}
      this.tableState['search'] = this.searchText
      this.getItems()

    },
    resetSearch() {
      this.searchFilter = false
      this.tableState = {}
      this.searchText = ""
      this.getItems()
    }
  }


}
</script>

<style lang="sass">
.ant-table .ant-table-container::before, .ant-table .ant-table-container::after
  width: 0px


</style>