import { createI18n } from 'vue-i18n'



export default createI18n({
    legacy: false,
    locale: localStorage.getItem('locale') || 'de',
    fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    globalInjection: true,
    messages: {
        de: {
            trackingNumber:"Sendungsnummer ",
            homeDesign:"Hauptseite",
            merchants:" Verkäufer ",
            registerDate:"Registrierungsdatum",
            package:"Paket",
            merchant:"Verkäufer",
            slides:"Slides",
            packages:"Pakete",
            backToPackages:"Zurück zu Seiten",
            payment:"Zahlung",
            paymentFailed:"Zahlung fehlgeschlagen",
            paymentSuccess:"Zahlung erfolgreich",
            tryAgain:"Erneut probieren",
            backToHome:"Zurück zu Startseite",
            paymentSuccessfullyCompleted:"Zahlung erfolgreich durchgeführt",
            addCategory:"Kategorie hinzufügen",
            addMainCategory:"Hauptkategorie hinzufügen",
            addSubCategory:"Unterkategorie hinzufügen",
            addingSubCategory:" Unterkategorie für Kategorie hinzufügen",
            orderStatus:"Bestellstatus",
            user:"Benutzer",
            table:"Tabelle",
            userEmail:"Benutzer E-Mail",
            thisWeek:"Diese Woche",
            update:"Update",
            refresh:"Erneuern",
            upgrade:"Upgrade",
            answered:"Beantwortet",
            doesntAnswered:"Nicht beantwortet",
            yourAnswer:"Ihre Antwort",
            thisMonth:"Diesen Monat",
            question:"Frage",
            emailVerified:"Email verifiziert",
            showInApp:"In der App angezeigt",
            upload:"Upload",
            productOptions:"Produktoptionen (Farbe, Größe,..)",
            newPassword:"Neues Passwort",
            writeCode:" Geben Sie den an Ihre Adresse gesendeten Code ein",
            writeNewPassword:"Neues Passwort eingeben",
            least6Char:" Das Passwort muss mindestens aus 6 Zeichen bestehen",
            passwordReset:" Passwort wurde zurück gesetzt",
            designType:"Design Typ",
            productsBackground:"Hintergrund und Produkte",
            productsBackgroundBrands:"Hintergrund, Produkte und Marken",
            HaveAccount:"Haben Sie kein Konto?",
            selectCategory:"Kategorie auswählen",
            value:"Wert",
            extraPrice:"Extra Preis",
            uploadInfo:" Zum Installieren hierher ziehen oder klicken",
            uploadInfo2:" Sie können mehrere Dateien hochladen",
            logo:"Logo",
            haveAccount:"Haben Sie ein Konto?",
            mobileApp:"Mobile App",
            payments:"Zahlungen",
            logout:"Abmelden",
            orderActive:" Benutzer können eine Bestellung aufgeben",
            offerActive:" Benutzer können eine Bestellung anfodern",
            showPrices:"Preise zeigen",
            yourCompany:"Ihr Unternehmen",
            design:"Design",
            publishTime: "Es kann bis zu 7 Tage dauern, bis Ihre App bei Google Play und im App Store genehmigt wird.",
            dontHaveAccount:"Sie haben kein Konto?",
            verifyEmailaddress:"Verifiziere deine E-Mail",
            verifyEmail:"Verifiziere deine E-Mail",
            password:"Passwort",
            googleIleDevam: "Mith Google fortfahren",
            facebookIleDevam: "Mith Facebook fortfahren",
            appleIleDevam: "Mith Apple fortfahren",
            monthlyPayment:"Monatlich Zahlen",
            yearlyPayment:"Jährlich Zahlen",
            plans:"Preispläne",
            month:"Monat",
            months:"Monate",
            total:"Gesamtsumme",
            publishableKey:"Veröffentlichbarer Schlüssel",
            secretKey:"GeheimSchlüssel",
            clientId:"Kunden-ID",
            clientSecret:"Client-Geheimnis",
            errorMessage:"Es ist ein Fehler aufgetreten",
            results:"Ergebnisse",
            brands:"Marken",
            categories:"Categorien",
            comments:"Bewertungen",
            revenue:"Einnahmen",
            orders:"Bestellungen",
            newCustomers:"Neue Kunden",
            newOrders:"Neue Bestellungen",
            bestSellingProducts:"Meistgekaufte Produkte",
            paymentOptions:"Zahlungsarten",
            home:"Startseite",
            name:"Name",
            lastname:"Nachname",
            email:"E-mail",
            date:"Datum",
            price:"Preis",
            products:"Produkte",
            shopSettings:"Shop-Einstellungen",
            shop:"Shop",
            search:"Suche",
            reset:"Zurücksetzen",
            period:"Zeitraum",
            questions:"Fragen",
            order:"Bestellen",
            contact:"Kontakt",
            settings:"Einstellungen",
            users:"Benutzer",
            completed:"Abgeschlossen",
            shipping:"Versand",
            preparing:"Vorbereitung",
            new:"Neu",
            cancel:"Abbrechen",
            paymentStatus:"Zahlungsstatus",
            paid:"Bezahlt",
            unpaid:"Unbezahlt",
            status:"Status",
            orderCreated:"Bestellung erstellt",
            orderUpdated:"Bestellung aktualisiert",
            paymentState:"Zahlungsstatus",
            paymentMethod:"Zahlungsart",
            offer:"Angebot",
            address:"Adresse",
            phone:"Telefon",
            fullAddress:"Vollständige Adresse",
            postcode:"PLZ",
            city:"Stadt",
            all:"Alle",
            forgetPassword:"Passwort vergessen?",
            login:"Anmelden",
            signup:"Registrieren",
            verify:"Verifizieren",
            details:"Einzelheiten",
            deleteConfirm:"Sind Sie sicher, dass Sie löschen möchten?",
            brand:"Marke",
            image:"Bild",
            delete:"Löschen",
            reOrder:"Sortieren",
            today:"Heute",
            yesterday:"Gestern",
            businessName:"Firmenname",
            street:"Straße",
            number:"Hausnr.",
            active:"Aktiv",
            stock:"Lager",
            title:"Titel",
            save:"Speichern",
            code:"Code",
            currency:"Währung",
            codeFalse:"Sie haben den Code falsch eingegeben",
            weSentCode:"Wir haben einen bestätigungscode gesendet",
            passwordAgain:"Passwort wiederholen",
            passwordSame:"Passwörter stimmen nicht überein",
            backgroundColor:"Hintergrundfarbe",
            background:"Hintergrund",
            description:"Beschreibung",
            unlimited:"Unbegrenzt",
            tryFree:"Kostenlos testen",
            or:"oder",
            checkInfo:"Prüfen Sie Ihre Informationen",
            category:"Kategorie",
            add:"Hinzufügen",
            emailAlreadyUsing:"E-Mail-Adresse wird bereits verwendet",
            later:"später",
            continue:"Weiter",
            appPublish: "App veröffentlichen",
            publish: "Veröffentlichen",
            statusBarIconColorBlack: "Schwarze Statusleistensymbole",
            textColor: "Text-Farbe",
            primaryColor: "Primärfarbe",
            requestProcessed: "Wir bearbeiten Ihre Anfrage",
            publishNow: "Jetzt veröffentlichen",
            back: "Zurück",
            edit: "Bearbeiten",
            googlePlayInfo: "Ihre Google Play-Informationen",
            appstorePlayInfo: "Ihre Appstore-Informationen",
            packageOk: "Das von Ihnen verwendete Paket ist kompatibel",
            packageNotOk: "Das von Ihnen verwendete Paket ist unzureichend.  Bitte upgraden Sie auf Enterprise-Paket.",
            packageNotOkPremium: "Das von Ihnen verwendete Paket ist unzureichend. Bitte upgraden Sie auf Premium-Paket.",
            processingAppOk: "Die Prozesse Ihrer App ist bei Google Play und im App Store fertiggestellt",
            processingApp: " Die Prozesse Ihrer App führen bei Google Play und im App Store weite ",
            publishOwnName: "Ich möchte mit meinem Firmennamen auf Google Play und im App Store veröffentlichen",
            publishAppmee: "Ich möchte in der Appmee-App veröffentlichen",
            unPublish: "Veröffentlichung aufheben",
            publishOnAppmee: "In der Appmee-App veröffentlicht.",
            givePermission: "Autorisieren Sie die folgende E-Mail-Adresse über Ihr Google Play Console-Konto.",
            howItWorks:"Wie funktioniert es?",
            level1:"Registrieren Sie sich auf der Seite und geben Sie dann die Produkte, Kategorien und Ihre Firmendaten ein.",
            level2: "Passen Sie die Farben, das Betriebssystem und die Zahlungsmethode Ihrer Anwendung nach Ihren Wünschen an.",
            level3: "Veröffentlichen Sie im letzten Schritt Ihre Website oder App. Sie haben zwei Möglichkeiten, Ihre App zu veröffentlichen. Wenn Sie möchten, können Sie eine Anwendung mit Ihrem eigenen Firmennamen im Appstore und bei Google Play veröffentlichen. Wenn Sie möchten, können Sie es auch in der Appme-App im Appstore und bei Google Play veröffentlichen.",
            sale: "Rabatt Prozent",
            redirectCname: "Ersetzen Sie diese durch CNAME von Appmee",
            domain: "Domain",
            nameserverInfo: "Registrare benötigen für die Bearbeitung von Nameserver-Updates bis zu 24 Stunden",
            manageYourWebsite: "Verwalten Sie Ihre Website",
            manageYourApp: "Verwalten Sie Ihre App",
            terms:"AGB",
            privacy:"Datenschutz",
            legal:"Impressum",
            customer: "Kunde",
            product: "Produkt",
            review: "Bewertung",
            star: "Stern",
            images: "Bilder",
            logoSquare: "Logo (Quadrat)",
            setupNotIncluded: "Die Installation ist nicht enthalten",
            setupIncluded: "Die Installation ist inbegriffen",
            wantInstall: "Ich möchte es installiert haben",
            approved: "Bestätigt",
            addSpecifyPricing: "Sonderpreis hinzufügen",
            specifyPricing: "Sonderpreis",
            discounts: "Rabatte",
            discountProductInfo: "Wenn Sie ein Produkt auswählen, kann der Rabatt nur für das ausgewählte Produkt verwendet werden",
            discountCategoryInfo: "Wenn Sie eine Kategorie auswählen, kann der Rabatt nur für Produkte in der ausgewählten Kategorie verwendet werden",
            discountUserInfo: "Wenn Sie einen Benutzer auswählen, kann der Rabatt nur von dem ausgewählten Benutzer verwendet werden",
            notificationMessage: "Benachrichtigungstext",
            minPrice: "Mindestbetrag im Warenkorb",
            fixed: "Fixierter Rabatt",
            percent: "Verhältnis",
            notifications:"Benachrichtigungen",
            sendNotification: "Benachrichtigung senden",
            defaultSelected: "Standard ausgewählt",
            orderWithoutPayment: "Kann ohne Bezahlung bestellt werden",
            totalOrders: "Alle Bestellungen",
            monthlyOrders: "Monatliche Bestellungen",
            weeklyOrders: "Wöchentliche Bestellungen",
            cancelUser: "Benutzer kündigen",
            approveUser: "Benutzer bestätigen",
            discountCoupon: "Rabattgutschein",
            shippingCost: "Versandkosten",
            freeShipping: "GRATIS Lieferung",
            freeShippingCostMinAmount: "Mindestbestellmenge für kostenlosen Versand",
            specialShippingCost: "Ich möchte einen speziellen Versandpreis für das Produkt festlegen",
            freeShippingCostInfo: "Wenn Null eingegeben wird, wird allen Bestellungen eine Versandgebühr hinzugefügt. Wenn zum Beispiel 10€ eingegeben wird, sind alle Bestellungen über 10€ versandkostenfrei.",
            priceColor: "Preis Farbe",
            discountColor: "Rabatt Farbe",
            unvan:"Titel",
            salut:"Anrede",
            subEnd: "Enddatum des Abonnements",
            updatePackage: "Update-Paket",
            darkMode: "Dunkelmodus",
            language: "Sprache",
            getStarted: "Jetzt kaufen",
            billingAddress: "Rechnungsadresse",
            companyName: "Firmenname",
            usttId: "USt ID",
            plz: "PLZ",
            homeNumber: "Hausnr.",
            installation: "Installieren",
            paynow: "Fortfahren und bezahlen",
            mobile: "Mobile App",
            addMode: "Hinzufügenmodus",
            removeMode: "Löschmodus",
            clickMode: "Klickmodus",
            moveMode: "Bewegungsmodus",
            courierZone: "Lieferzone",
            onlySpecifyArea: "Nur die unten markierten Gebiete bedienen.",
            connectDomainPlease:"Bitte verbinden Sie zunächst eine Domain"




        },
        tr:{
            homeDesign:"Ana Sayfa Tasarımı",
            merchants:"Satıcılar",
            registerDate:"Kayıt tarihi",
            package:"Paket",
            merchant:"Satıcı",
            slides:"Slaytlar",
            packages:"Paketler",
            backToPackages:"Geri Dön",
            payment:"Ödeme",
            paymentFailed:"Ödeme başarısız",
            paymentSuccess:"Ödeme başarılı",
            tryAgain:"Yeniden deneyin",
            backToHome:"Pana geri dön",
            paymentSuccessfullyCompleted:"Ödeme başarıyla tamamlandı",
            addCategory:"Kategori ekle",
            addMainCategory:"Ana kategori ekle",
            addSubCategory:"Alt kategori ekle",
            addingSubCategory:"Kategoriye Alt kategori ekle",
            orderStatus:"Sipariş durumu",
            user:"Kullanıcı",
            table:"Tablo",
            userEmail:"Kullanıcı E-postası",
            thisWeek:"Bu hafta",
            update:"Güncelle",
            refresh:"Yenile",
            upgrade:"Yükselt",
            answered:"Cevaplanan",
            doesntAnswered:"Cevaplanmayan",
            yourAnswer:"Senin cevabın",
            thisMonth:"Bu ay",
            question:"Soru",
            emailVerified:"E-posta doğrulandı",
            showInApp:"Uygulamada gösterilen",
            upload:"Yükle",
            productOptions:"Ürün seçenekleri (renk, boyut, vb.)",
            newPassword:"Yeni şifre",
            writeCode:"Adresinize gönderilen kodu girin",
            writeNewPassword:"Yeni şifreyi yaz",
            least6Char:"Parolada en az 6 karakter olmalı",
            passwordReset:"Şifre sıfırlama",
            designType:"Tasarım Tipi",
            productsBackground:"Arka plan ve ürünler",
            productsBackgroundBrands:"Arka plan, ürünler ve markalar",
            HaveAccount:"Hesabınız yok mu?",
            selectCategory:"Kategori seç",
            value:"Değer",
            extraPrice:"Ekstra Fiyat",
            uploadInfo:"Yüklemek için buraya sürükleyin veya tıklayın",
            uploadInfo2:"Birçok dosya yükleyebilirsiniz",
            logo:"Logo",
            haveAccount:"Hesabın var mı?",
            mobileApp:"Mobil Uygulama",
            payments:"Ödemeler",
            logout:"Oturumu Kapat",
            orderActive:"Kullanıcılar sipariş verebilir",
            offerActive:"Kullanıcılar teklif verebilir",
            showPrices:"Fiyatları göster",
            yourCompany:"Şirketiniz",
            design:"Tasarım",
            publishTime:"Google Play ve App Store'da uygulamanızın onaylanması 7 güne kadar sürebilir.",
            dontHaveAccount:"Hesabınız yok mu?",
            verifyEmailaddress:"E-Posta adresinizi doğrulayın",
            verifyEmail:"Epostayı doğrula",
            password:"Şifre",
            googleIleDevam: "Google ile devam et",
            facebookIleDevam: "Facebook ile devam et",
            appleIleDevam: "Apple ile devam et",
            monthlyPayment:"Aylık ödeme",
            yearlyPayment:"Yıllık ödeme",
            plans:"Fiyatlandırma",
            month:"Ay",
            months:"Ay",
            total:"Toplam",
            publishableKey:"Yayınlanabilir Anahtar",
            secretKey:"Gizli Anahtar",
            clientId:"Müşteri Kimliği",
            clientSecret:"Müşteri Gizliliği",
            errorMessage:"Bir hata oluştu",
            results:"Sonuçlar",
            brands:"Markalar",
            categories:"Kategoriler",
            comments:"Yorumlar",
            revenue:"Gelir",
            orders:"Siparişler",
            newCustomers:"Yeni Müşteriler",
            newOrders:"Yeni Siparişler",
            bestSellingProducts:"En Çok Satan Ürünler",
            paymentOptions:"Ödeme Seçenekleri",
            home:"Ana Sayfa",
            name:"İsim",
            lastname:"Soyad",
            email:"E-posta",
            date:"Tarih",
            price:"Fiyat",
            products:"Ürünler",
            shopSettings:"Mağaza Ayarları",
            shop:"Mağaza",
            search:"Arama",
            reset:"Sıfırla",
            period:"Dönem",
            questions:"Sorular",
            order:"Sipariş",
            contact:"İletişim",
            settings:"Ayarlar",
            users:"Kullanıcılar",
            completed:"Tamamlandı",
            shipping:"Kargo",
            preparing:"Hazırlık",
            new:"Yeni",
            cancel:"İptal",
            paymentStatus:"Ödeme Durumu",
            paid:"Ödenmiş",
            unpaid:"Ödenmemiş",
            status:"Durum",
            orderCreated:"Sipariş Oluşturuldu",
            orderUpdated:"Sipariş Güncellendi",
            paymentState:"Ödeme Durumu",
            paymentMethod:"Ödeme Yöntemi",
            offer:"Teklif",
            address:"Adres",
            phone:"Telefon",
            fullAddress:"Tam Adres",
            postcode:"Posta Kodu",
            city:"Şehir",
            all:"Hepsi",
            forgetPassword:"Şifremi Unuttum?",
            login:"Giriş Yap",
            signup:"Kayıt Ol",
            verify:"Doğrula",
            details:"Ayrıntılar",
            deleteConfirm:"Silmek istediğinize emin misiniz?",
            brand:"Marka",
            image:"Görsel",
            delete:"Sil",
            reOrder:"Sırala",
            today:"Bugün",
            yesterday:"Dün",
            businessName:"İşletme Adı",
            street:"Cadde",
            number:"Numara",
            active:"Aktif",
            stock:"Stok",
            title:"Başlık",
            save:"Kaydet",
            code:"Kod",
            currency:"Para Birimi",
            codeFalse:"Yanlış kod girdiniz",
            weSentCode:"Onay kodunu size gönderdik",
            passwordAgain:"Şifreyi Tekrarla",
            passwordSame:"Şifreler aynı değil",
            backgroundColor:"Arka Plan Rengi",
            background:"Arka Plan",
            description:"Açıklama",
            unlimited:"Sınırsız",
            tryFree:"Ücretsiz Deneme",
            or:"veya",
            checkInfo:"Bilgilerinizi kontrol edin",
            category:"Kategori",
            add:"Ekle",
            emailAlreadyUsing:"E-posta adresi zaten kullanılıyor",
            later:"sonra",
            continue:"Devam et",
            appPublish: "Uygulamayı Yayınla",
            publish: "Yayınlama",
            statusBarIconColorBlack: "Koyu durum çubuğu",
            textColor: "Metin rengi",
            primaryColor: "Birincil Renk",
            requestProcessed: "İsteğinizi işliyoruz",
            publishNow: "Şimdi yayınla",
            back: "Geri",
            edit: "Düzenle",
            googlePlayInfo: "Google Play bilgileriniz",
            appstorePlayInfo: "Appstore bilgileriniz",
            packageOk: "Kullandığınız paket uyumlu",
            packageNotOk: "Kullandığınız paket yetersiz. Lütfen Enterprise paketine yükseltin.",
            packageNotOkPremium: "Kullandığınız paket yetersiz. Lütfen en az Premium paketine yükseltin.",
            processingAppOk: "Uygulamanızın Google Play ve App Store'da işlemleri tamamlandı",
            processingApp: " Uygulamanızın Google Play ve App Store'da işlemleri devam ediyor ",
            publishOwnName: "Google Play ve App Store'da kendi firma ismimle yayınlamak istiyorum",
            publishAppmee: "Appmee uygulamasında yayınlamak istiyorum",
            unPublish: "Yayını kaldır",
            publishOnAppmee: "Appmee uygulamasında yayınlandı.",
            givePermission: "Google Play Console hesabınız üzerinden aşağıdaki e-posta adresini yetkilendirin.",
            howItWorks:"Nasıl çalışır?",
            level1:"Sayfaya kaydolun ve ardından ürünleri, kategorileri ve firma bilgilerini girin.",
            level2: "Uygulama renklerini, işletim sistemini ve ödeme yöntemini istediğiniz gibi ayarlayın.",
            level3: "Son adımda web sitesini veya uygulamayı yayınla. Uygulamanızı yayınlamak için iki seçeneğiniz var. İsterseniz Appstore ve Google Play'de kendi şirket isminizle bir uygulama yayınlayabilirsiniz. İsterseniz, Appstore ve Google Play'deki Appmee uygulamasında yayınlayabilirsiniz.",
            sale: "İndirim Yüzdesi",
            redirectCname: "CNAME kayıt bilgilerini aşağıdaki gibi değiştirin",
            domain: "Domain",
            nameserverInfo: "Güncelleme 24 saat kadar sürebilir",
            manageYourWebsite: "Web sitenizi yönetin",
            manageYourApp: "Uygulamanızı yönetin",
            terms:"Hüküm ve Koşullar",
            privacy:"Gizlilik",
            legal:"Yasal Bildirim",
            customer: "Müşteri",
            product: "Ürün",
            review: "Gözden Geçir",
            star: "Yıldız",
            images: "Görüntüler",
            logoSquare: "Logo (Kare)",
            setupNotIncluded: "Kurulum dahil değildir",
            setupIncluded: "Kurulum dahil",
            wantInstall: "Kurulum istiyorum",
            approved: "Onaylandı",
            addSpecifyPricing: "Özel fiyat ekle",
            specifyPricing: "Özel fiyat",
            discounts: "İndirim Kuponu",
            discountProductInfo: "Bir ürün seçtiğinizde, indirim sadece seçtiğiniz ürün için kullanılabilir",
            discountCategoryInfo: "Bir kategori seçtiğinizde, indirimin sadece seçtiğiniz kategori içerisindeki ürünler için kullanılabilir",
            discountUserInfo: "Bir kullanıcı seçtiğinizde, indirimin sadece seçtiğiniz kullanıcı tarafından kullanılabilir",
            notificationMessage: "Bildirim mesajı",
            minPrice: "Sepetin minimum tutarı",
            fixed: "Sabit indirim",
            percent: "Oran",
            notifications: "Bildirimler",
            sendNotification: "Bildirim gönder",
            defaultSelected: "Varsayılan seçilmiş",
            orderWithoutPayment: "Ödemeden sipariş edilebilir",
            totalOrders: "Tüm siparişler",
            monthlyOrders: "Aylık siparişler",
            weeklyOrders: "Haftalık siparişler",
            cancelUser: "Kullanıcı iptali",
            approveUser: "Kullanıcı onayı",
            discountCoupon: "İndirim kuponu",
            shippingCost: "Nakliye maliyeti",
            freeShipping: "ÜCRETSİZ teslimat",
            freeShippingCostMinAmount: "Kargo için minimum sipariş miktarı",
            specialShippingCost: "Ürün için özel sevkiyat ücreti belirlemek istiyorum",
            freeShippingCostInfo: "Sıfır girildiğinde, tüm siparişlere bir nakliye ücreti eklenecektir. Örneğin 10 € girerseniz, 10 € ın üzerindeki tüm siparişler ücretsiz kargo.",
            priceColor: "Fiyat rengi",
            discountColor: "İndirim rengi",
            unvan: "Başlık",
            salut:"Hitap",
            subEnd: "Abonelik bitiş tarihi",
            updatePackage: "Paketi güncelle",
            darkMode: "Karanlık mod",
            language: "Dil",
            getStarted: "Paketi seç",
            billingAddress: "Fatura Adresi",
            companyName: "Firma ismi",
            usttId: "USt ID",
            plz: "Posta kodu",
            homeNumber: "No",
            installation: "Kurulum",
            paynow: "Şimdi öde",
            mobile: "Mobil Uygulama",
            addMode: "Ekleme modu",
            removeMode: "Silme modu",
            clickMode: "Tıklama modu",
            moveMode: "Sürükleme modu",
            courierZone: "Hizmet bölgesi",
            onlySpecifyArea: "Sadece aşağıda işaretli bölgelere hizmet ver",
            connectDomainPlease:"Lütfen önce bir domain bağlayın"
        },
        en:{
            homeDesign:"Home Page Design",
            merchants:"Merchants",
            registerDate:"Registration Date",
            package:"Package",
            merchant:"Merchant",
            slides:"Slides",
            packages:"Packages",
            backToPackages:"Back to Packages",
            payment:"Payment",
            paymentFailed:"Payment Failed",
            paymentSuccess:"Payment Successful",
            tryAgain:"Try Again",
            backToHome: "Back to Homepage",
            paymentSuccessfullyCompleted: "Payment Successfully Completed",
            addCategory: "Add Category",
            addMainCategory: "Add Main Category",
            addSubCategory: "Add Subcategory",
            addingSubCategory: "Adding Subcategory for Category",
            orderStatus: "Order Status",
            user: "User",
            table: "Table",
            userEmail: "User Email",
            thisWeek: "This Week",
            update: "Update",
            refresh: "Refresh",
            upgrade: "Upgrade",
            answered: "Answered",
            doesntAnswered: "Not Answered",
            yourAnswer: "Your Answer",
            thisMonth: "This Month",
            question: "Question",
            emailVerified: "Email Verified",
            showInApp: "Shown in App",
            upload: "Upload",
            productOptions: "Product Options (Color, Size, etc.)",
            newPassword: "New Password",
            writeCode: "Enter the Code Sent to Your Address",
            writeNewPassword: "Enter New Password",
            least6Char: "Password Must Be at Least 6 Characters",
            passwordReset: "Password Reset",
            designType: "Design Type",
            productsBackground: "Background and Products",
            productsBackgroundBrands: "Background, Products, and Brands",
            HaveAccount: "Don't Have an Account?",
            selectCategory: "Select Category",
            value: "Value",
            extraPrice:"Additional Price",
            uploadInfo:"Drag and drop to install or click here",
            uploadInfo2:"You can upload multiple files",
            logo:"Logo",
            haveAccount:"Do you have an account?",
            mobileApp:"Mobile App",
            payments:"Payments",
            logout:"Logout",
            orderActive:"Users can place an order",
            offerActive:"Users can request an order",
            showPrices:"Show Prices",
            yourCompany:"Your Company",
            design:"Design",
            publishTime: "It can take up to 7 days for your app to be approved by Google Play and the App Store.",
            dontHaveAccount:"Don't have an account?",
            verifyEmailaddress:"Verify your email address",
            verifyEmail:"Verify your email",
            password:"Password",
            googleIleDevam: "Continue with Google",
            facebookIleDevam: "Continue with Facebook",
            appleIleDevam: "Continue with Apple",
            monthlyPayment:"Pay Monthly",
            yearlyPayment:"Pay Yearly",
            plans:"Pricing Plans",
            month:"Month",
            months:"Months",
            total:"Total",
            publishableKey:"Publishable Key",
            secretKey:"Secret Key",
            clientId:"Client ID",
            clientSecret:"Client Secret",
            errorMessage:"An error has occurred",
            results:"Results",
            brands:"Brands",
            categories:"Categories",
            comments:"Reviews",
            revenue:"Revenue",
            orders:"Orders",
            newCustomers:"New Customers",
            newOrders:"New Orders",
            bestSellingProducts:"Best Selling Products",
            paymentOptions:"Payment Options",
            home:"Home",
            name: "Name",
            lastname: "Last Name",
            email: "Email",
            date: "Date",
            price: "Price",
            products: "Products",
            shopSettings: "Store Settings",
            shop: "Store",
            search: "Search",
            reset: "Reset",
            period: "Period",
            questions: "Questions",
            order: "Order",
            contact: "Contact",
            settings: "Settings",
            users: "Users",
            completed: "Completed",
            shipping: "Shipping",
            preparing: "Preparing",
            new: "New",
            cancel: "Cancel",
            paymentStatus: "Payment Status",
            paid: "Paid",
            unpaid: "Unpaid",
            status: "Status",
            orderCreated: "Order Created",
            orderUpdated: "Order Updated",
            paymentState: "Payment State",
            paymentMethod: "Payment Method",
            offer: "Offer",
            address: "Address",
            phone: "Phone",
            fullAddress: "Full Address",
            postcode: "Postcode",
            city: "City",
            all: "All",
            forgetPassword: "Forgot Password?",
            login: "Login",
            signup: "Sign Up",
            verify: "Verify",
            details: "Details",
            deleteConfirm: "Are you sure you want to delete?",
            brand: "Brand",
            image: "Image",
            delete: "Delete",
            reOrder: "Reorder",
            today: "Today",
            yesterday: "Yesterday",
            businessName: "Business Name",
            street: "Street",
            number: "Number",
            active: "Active",
            stock: "Stock",
            title: "Title",
            save: "Save",
            code: "Code",
            currency:"Currency",
            codeFalse:"You have entered the code incorrectly",
            weSentCode:"We have sent a confirmation code",
            passwordAgain:"Repeat password",
            passwordSame:"Passwords do not match",
            backgroundColor:"Background color",
            background:"Background",
            description:"Description",
            unlimited:"Unlimited",
            tryFree:"Try for free",
            or:"or",
            checkInfo:"Check your information",
            category:"Category",
            add:"Add",
            emailAlreadyUsing:"Email address is already in use",
            later:"later",
            continue:"Continue",
            appPublish: "Publish App",
            publish: "Publish",
            statusBarIconColorBlack: "Black status bar icons",
            textColor: "Text color",
            primaryColor: "Primary color",
            requestProcessed: "We are processing your request",
            publishNow: "Publish now",
            back: "Back",
            edit: "Edit",
            googlePlayInfo: "Your Google Play information",
            appstorePlayInfo: "Your Appstore information",
            packageOk: "The package you are using is compatible",
            packageNotOk: "The package you are using is insufficient. Please upgrade to Enterprise package.",
            packageNotOkPremium: "The package you are using is insufficient. Please upgrade to Premium package.",
            processingAppOk: "The processes of your app is completed in Google Play and App Store",
            processingApp: "The processes of your app is running in Google Play and App Store",
            publishOwnName: "I want to publish with my company name in Google Play and App Store",
            publishAppmee: "I want to publish in Appmee App",
            unPublish: "Unpublish",
            publishOnAppmee: "Published in Appmee App.",
            givePermission: "Authorize the following email address through your Google Play Console account.",
            howItWorks:"How does it work?",
            level1:"Register on the page and then enter the products, categories and your company data.",
            level2: "Customize the colors, operating system and payment method of your application to your liking.",
            level3: "In the last step, publish your website or app. You have two options to publish your app. If you want, you can publish an application with your own company name in the Appstore and in Google Play. If you want, you can also publish it in the Appmee App in the Appstore and in Google Play.",
            sale: "Discount percentage",
            redirectCname: "Replace this with CNAME from Appmee",
            domain: "Domain",
            nameserverInfo: "Registrars may take up to 24 hours to process nameserver updates",
            manageYourWebsite: "Manage your website",
            manageYourApp: "Manage your app",
            terms:"Terms and Conditions",
            privacy:"Privacy Policy",
            legal:"Legal Notice",
            customer: "Customer",
            product: "Product",
            review: "Review",
            star: "Star",
            images: "Images",
            logoSquare: "Logo (Square)",
            setupNotIncluded: "Setup is not included",
            setupIncluded: "Setup is included",
            wantInstall: "I want it installed",
            approved: "Approved",
            addSpecifyPricing: "Add special pricing",
            specifyPricing: "Special pricing",
            discounts: "Discounts",
            discountProductInfo: "If you select a product, the discount can only be used for the selected product",
            discountCategoryInfo: "If you select a category, the discount can only be used for products in the selected category",
            discountUserInfo: "If you select a user, the discount can only be used by the selected user",
            notificationMessage: "Notification message",
            minPrice: "Minimum amount in cart",
            fixed: "Fixed discount",
            percent: "Percentage",
            notifications:"Notifications",
            sendNotification: "Send notification",
            defaultSelected: "Default selected",
            orderWithoutPayment: "Can be ordered without payment",
            totalOrders: "Total orders",
            monthlyOrders: "Monthly orders",
            weeklyOrders: "Weekly orders",
            cancelUser: "Cancel user",
            approveUser: "Approve user",
            discountCoupon: "Discount coupon",
            shippingCost: "Shipping cost",
            freeShipping: "FREE SHIPPING",
            freeShippingCostMinAmount: "Minimum order amount for free shipping",
            specialShippingCost: "I want to set a special shipping price for the product",
            freeShippingCostInfo: "If zero is entered, a shipping fee will be added to all orders. For example, if 10€ is entered, all orders over 10€ will be free shipping.",
            priceColor: "Price color",
            discountColor: "Discount color",
            unvan:"Title",
            salut:"Salutation",
            subEnd: "Subscription end date",
            updatePackage: "Update package",
            darkMode: "Dark mode",
            language: "Language",
            getStarted: "Get  started",
            billingAddress: "Billing Address",
            companyName: "Company name",
            usttId: "USt ID",
            plz: "Postcode",
            homeNumber: "Home number",
            installation: "Installation",
            paynow: "Pay now",
            mobile: "Mobile App",
            addMode: "Add mode",
            removeMode: "Delete modu",
            clickMode: "Click mode",
            moveMode: "Move mode",
            courierZone: "Shipping area",
            onlySpecifyArea: "Only serve the areas marked below",
            connectDomainPlease:"Please connect a domain first"

        }
    }
})
