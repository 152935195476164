<template>
  <a-modal :footer="null" v-model:visible="$store.modalStoreDiscount.showFormModal" >
    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >
      <a-form-item  :label="$t('title')">
        <a-input  v-model:value="formState.title"></a-input>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-model:checked="formState.sendNotification" >{{$t('sendNotification')}}</a-checkbox>
      </a-form-item>

      <a-form-item v-if="formState.sendNotification" :label="$t('notificationMessage')">
        <a-textarea  v-model:value="formState.notificationText"></a-textarea>
      </a-form-item>
      <a-form-item
          :label="$t('percent')"
          name="percent"
      >
        <a-input-number v-model:value="formState.percent"/>

      </a-form-item>
      <a-form-item
          :label="$t('category')"


      >
        <a-tree-select
            show-search
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            allow-clear
            tree-default-expand-all
            v-model:value="formState.category"

            :placeholder="$t('selectCategory')"
            :tree-data="$store.categoryStore.items.grouped"
        >

        </a-tree-select>
      </a-form-item>

      <a-form-item  :label="$t('brand')" >
        <a-select
            allow-clear
            v-model:value="formState.brand"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            :not-found-content="fetchingBrands ? undefined : null"
            :options="brands"
            @search="searchBrand"
        ></a-select>
      </a-form-item>
      <a-form-item
          :label="$t('fixed')"
          name="fixed"
      >
        <a-input-number v-model:value="formState.fixed"/>
        <span class="pl-2">{{ '  ' + (formState.fixed / 100).toFixed(2) + ' €' }}</span>
      </a-form-item>
      <a-form-item
          :label="$t('code')"
          name="code"
      >
        <a-input v-model:value="formState.code"/>
      </a-form-item>
<!--      <a-form-item-->
<!--          :label="$t('minCartAmount')"-->
<!--          name="minCart"-->
<!--      >-->
<!--        <a-input-number v-model:value="formState.minCart"/>-->
<!--        <span class="pl-2">{{ '  ' + (formState.minCart / 100).toFixed(2) + ' €' }}</span>-->
<!--      </a-form-item>-->
<!--      <a-form-item  >-->
<!--        <template #label>-->
<!--          {{$t('product') }}-->
<!--          <a-tooltip class="pl-1">-->
<!--            <template #title>-->
<!--              {{$t('discountProductInfo')}}-->
<!--            </template>-->
<!--           <question-circle-outlined/>-->
<!--          </a-tooltip>-->
<!--        </template>-->
<!--        <a-select-->
<!--            allow-clear-->
<!--            v-model:value="formState.products"-->
<!--            show-search-->
<!--            style="width: 100%"-->
<!--            label-in-value-->
<!--            :filter-option="false"-->
<!--            mode="multiple"-->
<!--            :not-found-content="fetching ? undefined : null"-->
<!--            :options="products"-->
<!--            @search="searchProduct"-->
<!--        ></a-select>-->
<!--      </a-form-item>-->

<!--      <a-form-item >-->
<!--        <template #label>-->
<!--          {{$t('category') }}-->
<!--          <a-tooltip class="pl-1">-->
<!--            <template #title>-->
<!--              {{$t('discountCategoryInfo')}}-->
<!--            </template>-->
<!--            <question-circle-outlined/>-->
<!--          </a-tooltip>-->
<!--        </template>-->
<!--        <a-select-->
<!--            allow-clear-->
<!--            v-model:value="formState.categories"-->
<!--            show-search-->
<!--            style="width: 100%"-->
<!--            label-in-value-->
<!--            mode="multiple"-->
<!--            :filter-option="false"-->
<!--            :not-found-content="fetchingCategories ? undefined : null"-->
<!--            :options="categories"-->
<!--            @search="searchCategory"-->
<!--        ></a-select>-->
<!--      </a-form-item>-->

      <a-form-item >
        <template #label>
          {{$t('user') }}
          <a-tooltip class="pl-1">
            <template #title>
              {{$t('discountUserInfo')}}
            </template>
            <question-circle-outlined/>
          </a-tooltip>
        </template>
        <a-select
            allow-clear
            v-model:value="formState.users"
            show-search
            style="width: 100%"
            label-in-value
            :filter-option="false"
            mode="multiple"
            :options="users"
            @search="searchUser"
        ></a-select>
      </a-form-item>

      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>


    </a-form>
  </a-modal>


</template>

<script>
import {defineComponent, reactive} from "vue";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {get, post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined,QuestionCircleOutlined} from '@ant-design/icons-vue';
import {ModalStoreDiscount} from "../../../stores/modal";


export default defineComponent({
  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
    QuestionCircleOutlined
  },
  props:{
    getItems:Function
  },


  setup() {

    const formState = reactive({
      sendNotification:false,
      notificationText: "",
      brand: null,
      category: null,
      minCart:0,
      percent:0,
      title:"",
      fixed:0,
      name:"",
      categories:[],
      products:[],
      users:[]
    });

    const {resetFields} = useForm(formState)
    return {formState, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null,
      fetching:false,
      fetchingCategories:false,
      products:[],
      categories:[],
      users:[],
      brands:[],
      fetchingBrands:false,
    }
  },
  created() {
    //console.log(this.$refs.quill)
  },
  mounted() {

    //console.log(this.$refs)
    if (this.$store.categoryStore.items.length < 1) {
      this.$store.categoryStore.getItems()
    }

    this.$store.modalStoreDiscount.$subscribe((mutation, state) => {

      if (state.showFormModal) {

        if (state.data) {
          console.log(state.data)

          this.formState.id = state.data.id
          this.formState.sendNotification = state.data.sendNotification
          this.formState.notificationText = state.data.notificationText
          this.formState.minCart = state.data.minCart ?? 0
          this.formState.percent = state.data.percent ?? 0
          this.formState.fixed = state.data.fixed ?? 0
          this.formState.title = state.data.title??""
          this.formState.code = state.data.code??""

          if (state.data.category) {
            console.log('evet')
            this.formState.category = {label:state.data.category.title,value:state.data.category.key}
          }
          if (state.data.brand) {
            this.formState.brand = {label:state.data.brand.name,value:state.data.brand.id}
          }

          if(state.data.products){
            this.formState.products = state.data.products.map((p)=>{
              return {label:p.title,key:p.id}
            })
          }
          if(state.data.categories){
            this.formState.categories = state.data.categories.map((p)=>{
              return {label:p.title,key:p.key}
            })
          }
          if(state.data.users){
            this.formState.users = state.data.users.map((p)=>{
              return {label:p.name + ' '+p.lastname,key:p.id}
            })
          }




        }
      } else {

        this.resetFields()
      }


    })
  },

  methods: {
    async searchBrand(value){
      let products = await get('brand',{'search':value})

      this.brands = products.json.data.map((product)=>{
        return {label:product.name,value:product.id}
      })
    },
    async searchProduct(value){
      let products = await get('product',{'search':value})
      //console.log(products.json.data)
      this.products = products.json.data.map((product)=>{
        return {label:product.title,value:product.id}
      })
    },
    async searchCategory(value){
      let products = await get('category',{'search':value})

      this.categories = products.json.data.nonGrouped.map((product)=>{
        return {label:product.title,value:product.key}
      })
    },
    async searchUser(value){
      let products = await get('users',{'search':value})

      this.users = products.json.data.map((user)=>{
        return {label:user.email + ' - ' + user.name+ ' ' + user.lastname,value:user.id}
      })
    },

    async onFinish(formState) {
      this.loading = true
      //console.log(formState)

      let data = {image:formState.image}



      data['products'] = formState.products
      console.log(formState)
      if(formState.category != null){
        data['category'] = formState.category
      }
      if(formState.brand != null){
        data['brand'] = formState.brand
      }


      data['users'] = formState.users
      data['id'] = formState.id
      data['sendNotification'] = formState.sendNotification
      data['notificationText'] = formState.notificationText
      data['minCart'] = formState.minCart ?? 0
      data['percent'] = formState.percent ?? 0
      data['fixed'] = formState.fixed ?? 0
      data['title'] = formState.title
      data['code'] = formState.code


      let result = await post('discount', data)
      //console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()
        this.getItems()

        this.$store.modalStoreDiscount.showFormModal = false


      }
    }
  }


})
</script>

<style lang="sass" scoped>
.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>