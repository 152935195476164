<template>

  <a-form
      :model="formState"
      name="basic"
      layout="vertical"
      autocomplete="off"
      @finish="onFinish(formState)"
      @finishFailed="onFinishFailed"
  >
    <a-form-item
        :label="$t('code')"
        name="code"
        :rules="[{ required: true}]"
    >
      <a-input  style="width: 100%" :controls="false" v-model:value="formState.code" />
    </a-form-item>

    <a-form-item>
      <a-button :loading="loading" class="login-form-button" type="primary" html-type="submit">{{$t('save')}}</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {post} from "@/helper/api";
import { message } from 'ant-design-vue';
import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {ConfigStore} from "@/stores/config";


const sleep = m => new Promise(r => setTimeout(r, m))



export default defineComponent({

  setup() {
    const configStore = ConfigStore();
    const formState = reactive({
      code: '',
    });


    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      configStore,
      onFinishFailed,
    };
  },
  mounted() {
    let jwt = localStorage.getItem('token');

  },
  data(){
    return {
      loading:false
    }
  },
  methods:{


    async onFinish(formState){

      this.loading=true
      await sleep(500)
      let result = await post("verifyCode",{
        "code":formState.code
      },true)

      if(!result.ok){
        displayErrorMessage()
        this.loading=false
        return;
      }


      if(result.json.status){

        localStorage.setItem('token',result.json.token)
        this.configStore.signupStep = 2

      }else{
        //console.log(result.json)
       displayErrorMessage(this.$t('codeFalse'))
        this.loading=false
      }






    }
  }


});


</script>
<style lang="sass" scoped>
.login-form-button
  width: 100%
.vertical-divider
  display: flex
  justify-content: center
  color: gray
  margin: 12px 0
.login
  margin: 35px
  .columns
    display: flex
    justify-content: center

  .login-box
    border: 1px solid #d8d8d8
    padding: 35px 35px 12px 35px

    .login-form-button
      margin-top: 25px


    .icon
      font-size: 16px
      margin-right: 8px


</style>



