<template>
  <a-form
      :model="formState"
      name="basic"
      layout="vertical"
      autocomplete="off"
      @finish="onFinish(formState)"
      @finishFailed="onFinishFailed"
  >
    <a-form-item
        :label="$t('email')"
        name="email"
        :rules="[{ required: true }]"
    >
      <a-input v-model:value="formState.email" />
    </a-form-item>
    <a-form-item
        :label="$t('salut')"
        name="salut"

    >
     <a-select v-model:value="formState.salut">
       <a-select-option value="Herr">Herr</a-select-option>
       <a-select-option value="Frau">Frau</a-select-option>
       <a-select-option value="Dr">Dr</a-select-option>
       <a-select-option value="Pro.Dr">Pro.Dr</a-select-option>
       <a-select-option value="Divers">Divers</a-select-option>
     </a-select>
    </a-form-item>
    <a-form-item
        :label="$t('unvan')"
        name="titel"

    >
      <a-input v-model:value="formState.titel" />
    </a-form-item>
    <a-form-item
        :label="$t('name')"
        name="name"
        :rules="[{ required: true }]"
    >
      <a-input v-model:value="formState.name" />
    </a-form-item>
    <a-form-item
        :label="$t('lastname')"
        name="lastname"
        :rules="[{ required: true }]"
    >
      <a-input v-model:value="formState.lastname" />
    </a-form-item>


    <a-form-item
        :label="$t('password')"
        name="password"
        :rules="[{ required: true}]"
    >
      <a-input-password v-model:value="formState.password" />
    </a-form-item>

    <a-form-item
        :label="$t('passwordAgain')"
        name="password2"
        :rules="[{ required: true,validator:validatePass2 }]"
    >
      <a-input-password v-model:value="formState.password2" />
    </a-form-item>

    <a-form-item>
      <a-button :loading="loading" class="login-form-button" type="primary" html-type="submit">{{$t('signup')}}</a-button>
      <div class="sign-up-text">
        {{$t('haveAccount')}}
        <router-link :to="{'name':'login'}">
          <a-button  type="link">{{$t('login')}}</a-button>
        </router-link>
      </div>

    </a-form-item>
      <span class="vertical-divider">{{$t('oder')}}</span>

    <social_login_buttons/>

  </a-form>
</template>

<script>
import {defineComponent, reactive} from "vue";
import {post} from "../../../helper/api";

import {displayErrorMessage} from "../../../helper/messages";
import Social_login_buttons from "../social_login_buttons.vue";

const sleep = m => new Promise(r => setTimeout(r, m))



export default defineComponent({
  metaInfo: {
    title: 'signup_form'
  },
  components:{Social_login_buttons},
  setup() {

    const formState = reactive({
      email: '',
      name: '',
      lastname: '',
      password: '',
      password2: '',
    });


    const onFinishFailed = errorInfo => {
      //console.log('Failed:', errorInfo);
    };

    let validatePass2 = async (_rule, value) => {

      if (formState.password2 === '') {
        return Promise.reject('');
      } else if (formState.password !== formState.password2) {
        return Promise.reject('');
      } else {
        return Promise.resolve();
      }
    };

    return {
      formState,
      validatePass2,
      onFinishFailed,
    };
  },
  mounted() {
    let jwt = localStorage.getItem('token');
    if(jwt != null){

      this.$router.push({'name':'dashboard_home'})
    }
  },
  data(){
    return {
      loading:false
    }
  },
  methods:{




    async onFinish(formState){

      this.loading=true
      await sleep(500)
      let result = await post("signup",formState)

      if(!result.ok){
        displayErrorMessage()
        this.loading=false
        return;
      }


      if(!result.json.error){

        localStorage.setItem('verifyEmail',formState.email)
        localStorage.setItem('tempToken',result.json.token)
        this.loading=false
        this.$router.push({name:'signupSteps'})
      }else{
        //console.log(result.json)
        if(result.json.error === true){
          displayErrorMessage()
        }else{
          displayErrorMessage(this.$t(result.json.error))
        }

        this.loading=false
      }






    }
  }


});


</script>
