<template>
  <div style="display: none">
    <print_pdf  :order="printOrder"/>
  </div>


  <a-tabs
      :tab-bar-style="{'padding-left':'14px','padding-right':'14px'}"

          v-model:activeKey="orderStatus" @change="tabChanged">
    <a-tab-pane key="all">
      <template #tab>
        <span>
           {{$t('all')}}<a-badge class="ml-1" count="0" :number-style="{
      backgroundColor: 'rgb(242, 242, 242)',
      color: 'rgb(121, 121, 121)'
    }"/>
        </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="preparing">
      <template #tab>
        <span>
         {{$t('preparing')}}<a-badge class="ml-1" count="0" :number-style="{
      backgroundColor: 'rgb(242, 242, 242)',
      color: 'rgb(121, 121, 121)'
    }"/>
        </span>
      </template>
    </a-tab-pane>

    <a-tab-pane key="delivery">
      <template #tab>
        <span>
         {{$t('shipping')}}<a-badge class="ml-1" count="0" :number-style="{
      backgroundColor: 'rgb(242, 242, 242)',
      color: 'rgb(121, 121, 121)'
    }"/>
        </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="completed">
      <template #tab>
        <span>
           {{$t('completed')}}<a-badge class="ml-1" count="0" :number-style="{
      backgroundColor: 'rgb(242, 242, 242)',
      color: 'rgb(121, 121, 121)'
    }"/>
        </span>
      </template>
    </a-tab-pane>



    <a-tab-pane key="canceled">
      <template #tab>
        <span>
           {{$t('cancel')}}<a-badge class="ml-1" count="0" :number-style="{
      backgroundColor: 'rgb(242, 242, 242)',
      color: 'rgb(121, 121, 121)'
    }"/>
        </span>
      </template>
    </a-tab-pane>
  </a-tabs>
  <div class="ml-3 mr-3 mb-3 has-text-right">
    <a-range-picker

        v-model:value="datePickerValue"
        class="mr-1"
        :show-time="{ format: 'HH:mm' }"
        format="YYYY-MM-DD HH:mm"

        :ranges="presetDates"
        @change="onRangeOk"

    />

    <a-dropdown class="m-1" :trigger="['click']" white>

      <template #overlay>
        <a-menu @click.stop >

          <a-menu-item  key="0">
            <a-checkbox  @change="updateColumns()" v-model:checked="showColumns.user">
             {{$t('user')}}
            </a-checkbox>
          </a-menu-item>
          <a-menu-item  key="1">
            <a-checkbox  @change="updateColumns()" v-model:checked="showColumns.status">
              {{$t('status')}}
            </a-checkbox>
          </a-menu-item>
          <a-menu-item  key="2">
            <a-checkbox  @change="updateColumns()" v-model:checked="showColumns.updated">
              {{$t('date')}}
            </a-checkbox>
          </a-menu-item>
          <a-menu-item  key="3">
            <a-checkbox  @change="updateColumns()" v-model:checked="showColumns.offer">
              {{$t('offer')}}
            </a-checkbox>
          </a-menu-item>
          <a-menu-item  key="4">
            <a-checkbox  @change="updateColumns()" v-model:checked="showColumns.amount">
              {{$t('total')}}
            </a-checkbox>
          </a-menu-item>

        </a-menu>
      </template>
      <a-button class="m-1">
        <table-outlined />
        {{$t('table')}}
        <DownOutlined />
      </a-button>

    </a-dropdown>
    <a-button class="m-1" @click="resetFilters()" white>
      <template #icon>
        <ReloadOutlined/>
      </template>
      {{$t('refresh')}}
    </a-button>
  </div>




    <a-table
        class="pl-3 pr-3"
        v-if="visible"
        :scroll="{ x: 'max-content' }"

        expandRowByClick
        style="width: 100%"
        :columns="columns"
        :row-key="order => order.id"
        :data-source="$store.orderStore.items"
        :pagination="pagination"
        :loading="$store.orderStore.loading"
        @change="tableStateChanged"
    >
      <template
          #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <a-input
              ref="searchInput"
              :placeholder="$t('userEmail')"

              v-model:value="searchTextUser"
              style="width: 188px; margin-bottom: 8px; display: block"
              @pressEnter="searchUser"
          />
          <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="searchUser"
          >
            <template #icon>
              <SearchOutlined/>
            </template>
            {{$t('search')}}
          </a-button>
          <a-button size="small" style="width: 90px" @click="resetSearchUser">
            {{$t('reset')}}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered,column }">

        <search-outlined v-if="column.dataIndex === 'user'" :style="{ color: userFilter ? '#108ee9' : undefined }"/>
        <filter-outlined v-else :style="{ color: statusFilter ? '#108ee9' : undefined }"/>
      </template>

      <template #expandedRowRender="{ record }">

        <OrderDetails :getItems="getItems" :order="record"/>

      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'updated'">
          <span>
             {{ parseDate(record.updated) }}
          </span>


        </template>

        <template v-if="column.dataIndex === undefined">
          <div class="is-flex is-flex-direction-row">

            <a-button @click.stop="printOrder = record;printBill()" type="text">
              <template #icon>
                <printer-outlined/>
              </template>
            </a-button>
            <a-button @click.stop="downloadBill(record)" type="text">
              <template #icon>

                <download-outlined/>
              </template>
            </a-button>


          </div>


        </template>



        <template v-if="column.dataIndex === 'status'">

          <span>
            <OrderStatus :order="record"/>
          </span>


        </template>
        <template v-if="column.dataIndex === 'user'">

          <span>
            {{record.user.email}}
          </span>


        </template>

        <template v-else-if="column.dataIndex === 'amount'">


          <span>
             {{((record.amount)/100).toFixed(2)+' '+$store.configStore.user.config.currency}}
          </span>


        </template>

        <template v-else-if="column.dataIndex === 'offer'">


          <span>
             {{((record.offer)/100).toFixed(2)+' '+$store.configStore.user.config.currency}}
          </span>


        </template>
      </template>
    </a-table>




</template>

<script>

import OrderStatus from './order_status.vue'
import SingleItem from "@/components/dashboard/crud/product/single.vue"
import OrderDetails from "./detail.vue";
import PrintBill from './print_bill.vue'
import Print_pdf from "./print_pdf.vue";



import {

  EyeOutlined,
  PrinterOutlined,
  ReloadOutlined,
  DownOutlined,
  UpOutlined,
    FileDoneOutlined,
  SearchOutlined,FilterOutlined,
    TableOutlined,
    DownloadOutlined,

} from "@ant-design/icons-vue"
import dayjs from "dayjs";
import {post} from "../../../../helper/api";


const sleep = m => new Promise(r => setTimeout(r, m))


export default {


  components: {
    OrderDetails,
    SingleItem,
    ReloadOutlined,
    PrinterOutlined,
    EyeOutlined,
    DownOutlined,
    UpOutlined,
    OrderStatus,
    PrintBill,
    FileDoneOutlined,
    SearchOutlined,
    FilterOutlined,
    TableOutlined,
    DownloadOutlined,
    Print_pdf
  },

  async mounted() {
    let today = this.$t('today')
    let yesterday = this.$t('yesterday')
    let week = this.$t('thisWeek')
    let month = this.$t('thisMonth')
    this.presetDates[today] = [dayjs().startOf('day'), dayjs().endOf('day')]
    this.presetDates[yesterday] = [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]
    this.presetDates[week] = [dayjs().startOf('week'), dayjs().endOf('week')]
    this.presetDates[month] = [dayjs().startOf('month'), dayjs().endOf('month').endOf('day')]
    await this.checkSavedTableState()

  },
  data() {
    return {

      showColumns:{
        'user':true,
        'status':true,
        'updated':true,
        'amount':true,
        'offer':false,
      },

      clickedOrder:null,
      loadingStatus:{},
      printElement: null,
      datePickerValue: null,
      visible: false,
      activeOrderId: 0,
      printOrder: null,
      presetDates: {},
      orderStatus: "all",
      loading: false,
      items: [],
      pagination: {

        pageSize: 20

      },
      columns: [],
      allColumns: [
        {
          title: this.$t('date'),
          dataIndex: 'updated',
          sorter: true,
          width: 'width: 100px',
        },
        {
          title: this.$t('user'),
          dataIndex: 'user',
          width: 'width: 80px',
          customFilterDropdown: true,
          onFilter: (value, record) =>
              console.log(value),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.input.focus();
              }, 100);
            }
          },
        },
        {
          title: this.$t('status'),
          dataIndex: 'status',
          width: 'width: 100px',
          filters: [
            {
              text: this.$t('new'),
              value: 'new',
            },
            {
              text: this.$t('preparing'),
              value: 'preparing',
            },
            {
              text: this.$t('shipping'),
              value: 'delivery',
            },
            {
              text: this.$t('completed'),
              value: 'completed',
            },
            {
              text: this.$t('cancel'),
              value: 'canceled',
            },
          ],
        },

        {
          title: this.$t('offer'),
          dataIndex: 'offer',
        },
        {
          title: this.$t('total'),
          dataIndex: 'amount',
          width: 'width: 100px',
        },
        {
          width: 'width: 100px',
        },

      ],
      tableState: {
        pagination: null, filters: null, sorter: null
      },
      searchTextUser:"",
      userFilter:false,
      statusFilter:false
    }

  },


  methods: {
    async downloadBill(order){
      this.printOrder = order
      const script = document.createElement('script');
      script.src = '/invoice/assets/js/html2canvas.min.js';
      document.body.appendChild(script);
      const script2 = document.createElement('script');
      script2.src = '/invoice/assets/js/html2pdf.js';
      document.body.appendChild(script2);
      await sleep(2000)

      var downloadSection = document.getElementById("bill")

      var options = {
        margin:       0,
        filename:     '#'+this.printOrder.id+'.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 3 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf(downloadSection, options);
    },
    async printBill(){
      await sleep(1000)



        const printHtml = window.open('', 'PRINT', 'height=600,width=600');

        printHtml.document.write('<html><head><link rel="stylesheet" href="/invoice/assets/css/style.css">');
        printHtml.document.write('</head><body>');
        printHtml.document.write(document.getElementById("bill").innerHTML);
        printHtml.document.write('</body></html>');
      await sleep(1000)


        printHtml.focus();
        await printHtml.print();
        printHtml.close()



    },
    async changeStatus(s){
      this.loadingStatus[this.clickedOrder.id] = true
      let status = ""
      if(s.key === '0'){
        status = 'completed'
      }
      if(s.key === '1'){
        status = 'shipping'
      }
      if(s.key === '2'){
        status = 'preparing'
      }
      if(s.key === '3'){
        status = 'new'
      }
      if(s.key === '4'){
        status = 'canceled'
      }



      await post('changeOrderStatus',{
        'id':this.clickedOrder.id,
        'status':status
      })
      this.loadingStatus[this.clickedOrder.id] = false
      this.getItems()
    },

    async tabChanged(value) {
      this.tableState.page = 1
      console.log(this.tableState)

      if (value !== 'all') {
        this.tableState.status = value
        await this.changeTableStatus(value)

      } else {
        delete this.tableState.status
        await this.changeTableStatus()
      }
      await this.getItems()
      localStorage.setItem('tableState', JSON.stringify(this.tableState))

    },
    async changeTableStatus(status) {
      this.statusFilter = true
      this.visible = false
      await sleep(1)
      for (let k = 0; k < this.columns.length; k++) {
        if (this.columns[k].dataIndex === 'status') {

          console.log('sil')
          if (status) {
            this.columns[k]['defaultFilteredValue'] = [status]
          } else {
            delete this.columns[k]['defaultFilteredValue']
          }

        }
      }
      console.log(this.columns)
      this.visible = true
    },
    updateColumns(){
      let newColumns = [];
      this.allColumns.forEach((column)=>{
        if(this.showColumns[column.dataIndex]){
          newColumns.push(column)
        }

      })
      newColumns.push(this.allColumns[this.allColumns.length-1])

      localStorage.setItem('showColumns',JSON.stringify(this.showColumns))
      this.columns = newColumns;
    },

    async checkSavedTableState() {
      let savedTableState = localStorage.getItem('tableState')
      let dateRange = localStorage.getItem('dateRange')
      let showColumnsStorage = localStorage.getItem('showColumns')
      console.log(savedTableState)
      if(showColumnsStorage){
        this.showColumns = JSON.parse(showColumnsStorage)
      }
      this.updateColumns()






      if (dateRange) {

        let json = Object.values(JSON.parse(dateRange))
        let dates = []
        json.forEach((i) => {
          dates.push(dayjs(i))
        })
        this.datePickerValue = dates
      }
      if (savedTableState) {
        this.tableState = JSON.parse(savedTableState)
        console.log(this.tableState)
        if (this.tableState.page) {
          this.pagination.defaultCurrent = this.tableState.page

        }
        if(this.tableState.status){
          this.orderStatus = this.tableState.status
        }

      } else {
        this.tableState = {
          pagination: null, filters: null, sorter: null
        }
      }


      for (let k = 0; k < this.columns.length; k++) {
        if (this.columns[k].dataIndex === 'status') {

          if (this.tableState.status) {

            this.columns[k]['defaultFilteredValue'] = Object.values(this.tableState.status)

          }
        }

        if (this.columns[k].dataIndex === 'updated') {
          if (this.tableState.sorter) {
            this.columns[k]['defaultSortOrder'] = this.tableState.sorter

          }


        }
      }


      await sleep(1)
      this.visible = true
      this.getItems()
    },
    resetFilters() {
      this.visible = false
      localStorage.removeItem('tableState')
      this.checkSavedTableState()

    },
    tableStateChanged(pagination, filters, sorter) {
      console.log(pagination)
      console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
      }
      if (filters) {
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['storeTable']) {
          filter['table'] = filters['storeTable']
        }
      }
      if (sorter && sorter.field && sorter.order) {
        filter['sorter'] = sorter.order
      }
      this.tableState = filter
      localStorage.setItem('tableState', JSON.stringify(this.tableState))
      this.getItems()
    },
    onRangeOk(date1) {
      console.log(this.datePickerValue)


      if (date1 == null) {
        localStorage.removeItem('dateRange')
        console.log('get items')
        this.getItems()
        return;
      }
      // this.firstDate = date1[0].format('YYYY-MM-DD HH:mm:ss')
      // this.secondDate = date1[1].format('YYYY-MM-DD HH:mm:ss')
      localStorage.setItem('dateRange', JSON.stringify(date1))
      this.getItems()
    },
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },

    searchUser() {
      this.userFilter = true
      this.tableState = {}
      this.tableState['searchUser'] = this.searchTextUser
      this.getItems()

    },
    resetSearchUser() {
      this.userFilter = false
      delete this.tableState['searchUser']
      this.searchTextUser=""

      this.getItems()
    },

    async getItems() {

      let filter = this.tableState


      if (this.datePickerValue) {

        filter['firstDate'] = this.datePickerValue[0].format('YYYY-MM-DD HH:mm:ss')
        filter['secondDate'] = this.datePickerValue[1].format('YYYY-MM-DD HH:mm:ss')

      } else {
        delete filter['firstDate']
        delete filter['secondDate']

      }

      await this.$store.orderStore.getItems(filter)

      this.pagination.total = this.$store.orderStore.result.json.totalItemCount

    }
  }


}
</script>

<style lang="sass">

.ant-pagination-options
  display: none
.index-middle
  margin: 12px 24px

.ant-table-cell
  vertical-align: middle


</style>