<script setup>
import EmailVerifyForm from '@/components/dashboard/forms/email_verify_form.vue'
</script>
<template>
  <div class="page-start">

    <div class="container is-fullhd login">
      <div class="columns">
        <div class="box login-box column is-6 is-4-fullhd">
          <div class="top-area">
            <router-link :to="{name:'login'}">

            <div class="back-icon">
              <ion-icon name="chevron-back-circle-outline"></ion-icon>
            </div>
            </router-link>
            <h1 class="title is-4 has-text-centered">{{$t('verifyEmail')}}</h1>
          </div>
          <p class="pb-3">{{ $t('weSentCode') }}</p>

          <EmailVerifyForm/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "verify_email",
  data() {
    return {
      email: ""
    }
  },
  mounted() {
    this.email = localStorage.getItem('verifyEmail')
    //console.log(this.email)
  }
}
</script>

<style lang="sass" scoped>
.top-area
  display: flex
  flex-direction: row
  margin-bottom: 25px
  align-items: center
  .back-icon
    display: flex
    align-items: center
    margin-right: 8px

</style>