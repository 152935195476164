import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ant from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import vue3GoogleLogin from 'vue3-google-login'
import { createMetaManager } from 'vue-meta'




const app = createApp(App)

app.use(i18n)
app.use(createPinia())
app.use(router)

app.use(ant)
app.config.globalProperties.$store = {};
app.use(vue3GoogleLogin, {
    clientId: '340740750091-on6i34oknjglne4pdai08641hesg0ti2.apps.googleusercontent.com'
})
app.use(createMetaManager())
app.mount('#app')



