<template>

  <div class="columns m-0 mb-4">
    <div class="column p-0 m-0 is-flex is-align-items-center">
      <span class="has-text-weight-medium"> #{{ order.id }}</span>
    </div>
    <div class="column p-0 m-0 has-text-right">
      <update_offer v-if="order.type==='offer'" :order="order" :getItems="getItems" />
      <a-dropdown :trigger="['click']">
        <a-button :loading="loadingStatus" class="m-1" size="small">
          <template #icon>
            <truck class="anticon" />
          </template>
          {{ $t("orderStatus") }}

        </a-button>
        <template #overlay>
          <a-menu @click="changeStatus">
            <a-menu-item key="0">
              <a> {{ $t("completed") }}</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a> {{ $t("shipping") }}</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a> {{ $t("preparing") }}</a>
            </a-menu-item>
            <a-menu-item key="3">
              <a> {{ $t("new") }}</a>
            </a-menu-item>
            <a-menu-item key="4">
              <a> {{ $t("cancel") }}</a>
            </a-menu-item>

          </a-menu>
        </template>
      </a-dropdown>

      <a-dropdown :trigger="['click']">
        <a-button :loading="loadingPaymentStatus" class="m-1" size="small">
          <template #icon>
            <credit-card-outlined />
          </template>
          {{ $t("paymentStatus") }}
        </a-button>
        <template #overlay>
          <a-menu @click="changePaymentStatus">
            <a-menu-item key="0">
              <a>{{ $t("paid") }}</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a>{{ $t("unpaid") }}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-dropdown @click="trackingNumberShow=!trackingNumberShow" :visible="trackingNumberShow" :trigger="['click']" placement="bottomRight">
        <a-button :loading="loadingPaymentStatus" class="m-1" size="small">
          <template #icon>
            <truck class="anticon" />
          </template>
          {{ $t("trackingNumber") }}
        </a-button>
        <template #overlay>
          <div @click.stop class="p-5 white-background mt-2">
            <a-button @click="trackingNumberShow=false  " type="text" style="position: absolute;top:8px;right:0;z-index: 99999">
              <template #icon>
                <close-outlined style="color:red"/>
              </template>
            </a-button>
            <a-input v-model:value="trackingNumber" >


            </a-input>
            <a-button :loading="loadingTracking" class="mt-2" @click="saveTrackingNumber">
              {{$t("save")}}
            </a-button>
          </div>

        </template>
      </a-dropdown>

      <a-popconfirm
        placement="left"
        :title="$t('deleteConfirm')"
        @confirm="deleteOrder()">
        <a-button :loading="loadingDelete" danger size="small">
          <template #icon>
            <DeleteOutlined />
          </template>
          {{ $t("delete") }}
        </a-button>
      </a-popconfirm>

    </div>
  </div>
  <div class="columns p-0 m-0 mb-4">
    <div class="column is-4 p-0 m-0">
      <div class="info-line"><span class="left">{{ $t("status") }}: </span><span class="right">

        <OrderStatus :order="order" />
      </span></div>
      <div class="info-line"><span class="left">{{ $t("orderCreated") }}: </span><span
        class="right ">{{ parseDate(order.created) }}</span></div>
      <div class="info-line"><span class="left">{{ $t("orderUpdated") }}: </span><span
        class="right ">{{ parseDate(order.updated) }}</span></div>
      <div class="info-line"><span class="left">{{ $t("trackingNumber") }}: </span><span
          class="right">{{ order.trackingNumber??"-" }}</span></div>
    </div>
    <div class="column is-4 p-0 m-0">

    </div>
    <div class="column is-4 p-0 m-0 ">

      <div class="info-line">
        <span class="left">{{ $t("paymentState") }}: </span><span :style="order.paid ? 'color:green':'color:red'"
                                                                  class="right">{{ order.paid ? $t("paid") : $t("unpaid") }}

      </span>
      </div>

      <div class="info-line"><span class="left">{{ $t("paymentMethod") }}: </span><span
        class="right">{{ order.paymentMethod }}</span></div>



      <div class="info-line">
        <span class="left">{{ $t("total") }}: </span><span
        class="right">{{ ((order.amount) / 100).toFixed(2) + " €" }}</span>
      </div>
      <div v-if="order.type==='offer'" class="info-line">
        <span class="left">{{ $t("offer") }}: </span><span
        class="right">{{ ((order.offer < 0 ? 0 : order.offer) / 100).toFixed(2) + " €" }}</span>
      </div>

    </div>
  </div>

  <div class="info-title">
    {{ $t("products") }}
  </div>
  <SingleProduct v-for="product in order.cartProducts" :data="product" :key="product.id" />
  <div v-if="order.discount != null" class="info-title">
    {{ $t("discountCoupon") }}
  </div>

  <a-card v-if="order.discount != null" style="max-width:400px" :body-style="{padding:' 12px'}">
    <div class="single-discount profile-card">
      <div  class="title">
        {{ order.discount.title }}
      </div>
      <div class="sale">
        {{ order.discount.percent === 0 ? (order.discount.fixed / 100).toFixed(2) + " " + $store.configStore.user.config.currency : "%" + order.discount.percent }}
        (-{{ order.discount.percent === 0 ? ((order.discount.fixed / 100).toFixed(2)) : (order.amount / 100 * order.discount.percent / 100).toFixed(2)
        }} {{ " " + $store.configStore.user.config.currency }})

      </div>
    </div>
  </a-card>
  <div class="info-title">
    {{ $t("address") }}
  </div>
  <a-card :body-style="{padding:' 12px'}">
    <div class="info-line">
      <span class="left">{{ $t("name") }}: </span>
      <span class="right">{{ order.address.name }}</span>
    </div>
    <div class="info-line">
      <span class="left">{{ $t("lastname") }}: </span>
      <span class="right">{{ order.address.lastname }}</span>
    </div>
    <div class="info-line">
      <span class="left">{{ $t("phone") }}: </span>
      <span class="right">{{ order.address.phone }}</span>
    </div>
    <div class="info-line">
      <span class="left">{{ $t("fullAddress") }}: </span>
      <span class="right">{{ order.address.fullAddress }}</span>
    </div>
    <div class="info-line">
      <span class="left">{{ $t("postcode") }}: </span>
      <span class="right">{{ order.address.postcode }}</span>
    </div>
    <div class="info-line">
      <span class="left">{{ $t("city") }}: </span>
      <span class="right">{{ order.address.city }}</span>
    </div>
  </a-card>
  <div v-if="order.note">
    <div class="info-title">
      {{ $t("note") }}
    </div>
    <a-card :body-style="{padding:' 12px'}">
      {{ order.note }}
    </a-card>
  </div>


</template>

<script>
import SingleProduct from "./single_product.vue";
import OrderStatus from "./order_status.vue";
import { DeleteOutlined, StarOutlined, GoldOutlined, CreditCardOutlined,CloseOutlined } from "@ant-design/icons-vue";
import { Truck } from "@icon-park/vue-next";
import { post } from "../../../../helper/api";
import Update_offer from "./update_offer.vue";

export default {
  name: "OrderDetails",
  components: {
    Update_offer,
    SingleProduct,
    OrderStatus,
    DeleteOutlined,
    StarOutlined,
    GoldOutlined,
    CreditCardOutlined,
    Truck,
    CloseOutlined
  },
  props: {
    order: Object,
    getItems: Function
  },
  data() {
    return {
      loadingStatus: false,
      loadingTable: false,
      loadingPaymentStatus: false,
      loadingDelete: false,
      trackingNumberShow: false,
      loadingTracking: false,
      trackingNumber:""
    };
  },
  methods: {
    async deleteOrder() {
      this.loadingDelete = true;
      await post("order/delete", {
        "id": this.order.id
      });

      this.getItems();
      this.loadingDelete = false;
    },
    async saveTrackingNumber() {
      this.loadingTracking = true;
      await post("order/trackingNumber", {
        "trackingNumber": this.trackingNumber,
        "id":this.order.id
      });
      this.trackingNumberShow = false

      this.getItems();
      this.loadingTracking = false;
    },
    async changePaymentStatus(s) {
      console.log(s.key === "0");
      this.loadingPaymentStatus = true;
      await post("changePaymentStatus", {
        "id": this.order.id,
        "status": s.key === "0"
      });

      this.getItems();
      this.loadingPaymentStatus = false;
    },

    parseDate(dateString) {
      let date = new Date(dateString);
      return (date.getDate()).toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2, "0") + "." + date.getFullYear() + " " + date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0");
    },
    async changeTable(s) {
      console.log(s);
      this.loadingTable = true;


      await post("changeOrderTable", {
        "id": this.order.id,
        "table": s.key
      });
      this.loadingTable = false;
      this.getItems();
    },
    async changeStatus(s) {
      this.loadingStatus = true;
      let status = "";
      if (s.key === "0") {
        status = "completed";
      }
      if (s.key === "1") {
        status = "shipping";
      }
      if (s.key === "2") {
        status = "preparing";
      }
      if (s.key === "3") {
        status = "new";
      }
      if (s.key === "4") {
        status = "canceled";
      }

      await post("changeOrderStatus", {
        "id": this.order.id,
        "status": status
      });
      this.loadingStatus = false;
      this.getItems();


    }
  }
};
</script>

<style lang="sass" scoped>

.single-discount
  display: flex
  flex-direction: row
  justify-content: space-between
  .title
    font-size: 14px
    font-weight: normal
    margin: 0
    line-height: 1.5715
  .sale
    font-weight: bold
.info-line
  margin-bottom: 8px

  .left
    color: rgb(102, 102, 102)

  .right
    font-weight: 500

.info-title
  margin: 24px 0 24px 0
  display: flex
  flex-direction: row
  align-items: center
  font-size: 16px


.added-order
  padding-left: 8px
  margin: 12px 0
  border-left: 2px solid #ff7116

.bottom
  display: flex
  flex-direction: row
  justify-content: space-between

  .right
    display: flex
    flex-direction: row
    justify-content: flex-end
</style>