<template>


    <a-tabs :tab-bar-style="{'padding-left':'14px','padding-right':'14px'}" @change="getItems" v-model:activeKey="status">
      <a-tab-pane key="all">
        <template #tab>
        <span>
          <message-outlined />
          {{$t('all')}}
        </span>
        </template>
      </a-tab-pane>
      <a-tab-pane key="answered">
        <template #tab>
        <span>
          <check-outlined />
          {{$t('answered')}}
        </span>
        </template>
      </a-tab-pane>
      <a-tab-pane key="doesntAnswered" >
        <template #tab>
        <span>
          <close-outlined />
          {{$t('doesntAnswered')}}
        </span>
        </template>
      </a-tab-pane>
    </a-tabs>
    <a-table
        class="pl-3 pr-3"
        :scroll="{ x: 'max-content' }"
        expandRowByClick
        style="width: 100%"
        :row-key="data => data.id"
        :columns="columns"
        :data-source="questions"
        :pagination="pagination"
        :loading="loading"
        @change="tableStateChanged"
    >

      <template #expandedRowRender="{ record }">
      <a-form layout="vertical">
        <a-form-item
        :label="$t('yourAnswer')"

        >
          <a-input v-model:value="record.answer" />


        </a-form-item>
        <a-form-item>
          <a-button :loading="loadingSave" @click="save(record.id,record.answer)" type="primary">
            <template #icon>
              <save-outlined/>
            </template>
            {{$t('save')}}
          </a-button>
        </a-form-item>
      </a-form>
      </template>


      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'status'">

          <div style="height: 8px;width: 8px;border-radius: 50px;" :style="'background-color:'+(record.answer == null ? 'red':'green')">

          </div>


        </template>
        <template v-if="column.dataIndex === 'product'">

          <a-button :style="{'padding-left':'0'}" @click.stop="showProductStore.showFormModal=true;product=record.product" type="link">
            {{record.product.title}}
          </a-button>


        </template>
        <template v-if="column.dataIndex === 'price'">
      <span>
        {{ (record.price / 100).toFixed(2) + ' ' + this.$store.configStore.user.currency }}
      </span>
        </template>
        <template v-if="column.dataIndex === 'thumbnail'">

          <img v-if="record.thumbnail" height="40" width="40" :src="record.thumbnail ? 'https://cdn.bringsdelivery.de/'+record.thumbnail:''">
        </template>
        <template v-if="column.dataIndex === undefined">

          <a-popconfirm placement="left" :title="$t('deleteConfirm')"
                        @confirm="deleteProduct(record.id)">
            <a-button @click.stop type="text">
              <template #icon>
                <DeleteOutlined/>
              </template>
            </a-button>

          </a-popconfirm>
        </template>
      </template>

    </a-table>

  <show_product_modal :show-product="showProduct" :product="product"/>
</template>
<script>

import {ShowProductStore} from "@/stores/modal";
import SingleItem from "@/components/dashboard/crud/product/single.vue";

import {SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined,CloseOutlined,CheckOutlined,MessageOutlined,SaveOutlined} from "@ant-design/icons-vue";
import {post} from "@/helper/api";
import {get} from "../../../../helper/api";
import Show_product_modal from "../../modals/show_product_modal.vue";
import {displaySuccessMessage} from "../../../../helper/messages";


export default {


  components: {SingleItem, SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined,CloseOutlined,CheckOutlined,MessageOutlined,SaveOutlined,Show_product_modal},
  data() {
    return {
      columns: [],
      pagination: {
        pageSize: 20,
        current: 1
      },
      searchText: "",
      loadingDelete: false,
      loadingSave: false,
      searchFilter: false,
      status:"all",
      loading:false,
      questions:[],
      showProduct:false,
      product:{},
      showProductStore:{}

    }
  },

  mounted() {
    this.showProductStore = ShowProductStore();

    this.columns = [

      {
        dataIndex: 'status',
        width:'10px'
      },
      {
        title: this.$t('product'),
        dataIndex: 'product',
        width:'200px'
      },
      {
        title: this.$t('question'),
        dataIndex: 'content'
      },
      {
        title: '',
        width: '4px'
      },

    ]
    this.getItems()


  },
  methods: {
    async deleteProduct(id) {
      this.loadingDelete = true
      await post('question/delete', {
        id: id
      })
      this.loadingDelete = false
      await this.getItems()

    },
    async save(id,answer) {
      this.loadingSave = true
      await post('question', {
        id: id,
        answer:answer
      })
      this.loadingSave = false
      displaySuccessMessage()
      await this.getItems()

    },
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },
    async getItems() {
      this.loading=true
      let result = await get('question',this.tableState)
      console.log(result)
      this.questions  = result.json.data
      this.pagination.total = result.json.totalItemCount
      this.loading=false
    },
    tableStateChanged(pagination, filters, sorter) {
      console.log(pagination)
      console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
        this.pagination = pagination
      }
      if (filters) {
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['stock']) {
          filter['stock'] = filters['stock']
        }
      }
      if (sorter && sorter.field && sorter.order) {
        filter['sorter'] = sorter.order
      }
      this.tableState = filter
      localStorage.setItem('tableState', JSON.stringify(this.tableState))
      this.getItems()
    },

  }


}
</script>

<style lang="sass">
.ant-table .ant-table-container::before, .ant-table .ant-table-container::after
  width: 0px


</style>