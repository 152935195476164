<script setup>

import LineChart  from '@/components/dashboard/charts/line_chart.vue'
import PieChart  from '@/components/dashboard/charts/pie_chart.vue'
</script>


<template>

  <div v-if="loading" class="is-flex is-justify-content-center p-4">
    <loading-outlined style="font-size: 30px" />
  </div>



  <div  class="columns is-multiline p-3">
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5">
        <span class="title-icon image-color">
            <svg id="katman_1" data-name="katman 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
          <path d="M15,22.75H9c-5.43,0-7.75-2.32-7.75-7.75V9C1.25,3.57,3.57,1.25,9,1.25h6c5.43,0,7.75,2.32,7.75,7.75v6C22.75,20.43,20.43,22.75,15,22.75Zm-6-20C4.39,2.75,2.75,4.39,2.75,9v6c0,4.61,1.64,6.25,6.25,6.25h6c4.61,0,6.25-1.64,6.25-6.25V9c0-4.61-1.64-6.25-6.25-6.25Z" transform="translate(-1.25 -1.25)" />
          <text transform="translate(6.11 15.78)" style="font-size: 14.822269439697266px">€</text>
        </svg>
        </span>


        {{$t('revenue')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart id="revenueChart" price :data="stats.orderRevenue"/>
      </a-card>
    </div>
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5">
        <span class="title-icon image-color">
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M12 19.75C9.38 19.75 7.25 17.62 7.25 15C7.25 12.38 9.38 10.25 12 10.25C14.62 10.25 16.75 12.38 16.75 15C16.75 17.62 14.62 19.75 12 19.75ZM12 11.75C10.21 11.75 8.75 13.21 8.75 15C8.75 16.79 10.21 18.25 12 18.25C13.79 18.25 15.25 16.79 15.25 15C15.25 13.21 13.79 11.75 12 11.75Z" />
          <path d="M11.4399 16.54C11.1199 16.54 10.7999 16.42 10.5599 16.17L9.90988 15.52C9.61988 15.23 9.61988 14.75 9.90988 14.46C10.1999 14.17 10.6799 14.17 10.9699 14.46L11.4499 14.94L13.0599 13.46C13.3599 13.18 13.8399 13.2 14.1199 13.5C14.3999 13.8 14.3799 14.28 14.0799 14.56L12.2999 16.2C12.0499 16.43 11.7399 16.54 11.4399 16.54Z" />
          <path d="M14.9998 22.75H8.99982C4.37982 22.75 3.51982 20.6 3.29982 18.51L2.54982 12.52C2.43982 11.44 2.39982 9.89 3.44982 8.73C4.34982 7.73 5.83982 7.25 7.99982 7.25H15.9998C18.1698 7.25 19.6598 7.74 20.5498 8.73C21.5898 9.89 21.5598 11.44 21.4498 12.5L20.6998 18.51C20.4798 20.6 19.6198 22.75 14.9998 22.75ZM7.99982 8.75C6.30982 8.75 5.14982 9.08 4.55982 9.74C4.06982 10.28 3.90982 11.11 4.03982 12.35L4.78982 18.34C4.95982 19.94 5.39982 21.26 8.99982 21.26H14.9998C18.5998 21.26 19.0398 19.95 19.2098 18.36L19.9598 12.35C20.0898 11.13 19.9298 10.3 19.4398 9.75C18.8498 9.08 17.6898 8.75 15.9998 8.75H7.99982Z" />
          <path d="M16.5 8.63005C16.09 8.63005 15.75 8.29005 15.75 7.88005V6.50005C15.75 5.45006 15.3 4.43005 14.52 3.72005C13.73 3.00005 12.7 2.67005 11.63 2.77005C9.83 2.94005 8.25 4.78005 8.25 6.70005V7.67005C8.25 8.08005 7.91 8.42005 7.5 8.42005C7.09 8.42005 6.75 8.08005 6.75 7.67005V6.69005C6.75 4.00005 8.92 1.52005 11.49 1.27005C12.99 1.13005 14.43 1.60005 15.53 2.61005C16.62 3.60005 17.25 5.02005 17.25 6.50005V7.88005C17.25 8.29005 16.91 8.63005 16.5 8.63005Z"/>
        </svg>
        </span>

        {{$t('orders')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart :price="false" id="ordersChart" :data="stats.orders"/>
      </a-card>
    </div>

    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5"><PeoplesTwo style="padding-right: 5px;height:30px;display: flex;align-items: center;font-size:22px"/>{{$t('newCustomers')}}</h2>
      <a-card v-if="stats" :body-style="{'padding':'8px'}">
        <LineChart :price="false" id="clientsChart" :data="stats.clients"/>
      </a-card>
    </div>
    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5">
<span class="title-icon image-color">
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M12 19.75C9.38 19.75 7.25 17.62 7.25 15C7.25 12.38 9.38 10.25 12 10.25C14.62 10.25 16.75 12.38 16.75 15C16.75 17.62 14.62 19.75 12 19.75ZM12 11.75C10.21 11.75 8.75 13.21 8.75 15C8.75 16.79 10.21 18.25 12 18.25C13.79 18.25 15.25 16.79 15.25 15C15.25 13.21 13.79 11.75 12 11.75Z" />
          <path d="M10.9999 16.75C10.7499 16.75 10.4999 16.62 10.3599 16.39C10.1499 16.03 10.2599 15.57 10.6199 15.36L11.3799 14.9C11.4599 14.85 11.4999 14.77 11.4999 14.69V13.76C11.4999 13.35 11.8399 13.01 12.2499 13.01C12.6599 13.01 12.9999 13.34 12.9999 13.75V14.68C12.9999 15.29 12.6699 15.87 12.1499 16.18L11.3799 16.64C11.2699 16.72 11.1299 16.75 10.9999 16.75Z" />
          <path d="M14.9998 22.75H8.99982C4.37982 22.75 3.51982 20.6 3.29982 18.51L2.54982 12.52C2.43982 11.44 2.39982 9.89 3.44982 8.73C4.34982 7.73 5.83982 7.25 7.99982 7.25H15.9998C18.1698 7.25 19.6598 7.74 20.5498 8.73C21.5898 9.89 21.5598 11.44 21.4498 12.5L20.6998 18.51C20.4798 20.6 19.6198 22.75 14.9998 22.75ZM7.99982 8.75C6.30982 8.75 5.14982 9.08 4.55982 9.74C4.06982 10.28 3.90982 11.11 4.03982 12.35L4.78982 18.34C4.95982 19.94 5.39982 21.26 8.99982 21.26H14.9998C18.5998 21.26 19.0398 19.95 19.2098 18.36L19.9598 12.35C20.0898 11.13 19.9298 10.3 19.4398 9.75C18.8498 9.08 17.6898 8.75 15.9998 8.75H7.99982Z"/>
          <path d="M16.5 8.63005C16.09 8.63005 15.75 8.29005 15.75 7.88005V6.50005C15.75 5.45006 15.3 4.43005 14.52 3.72005C13.73 3.00005 12.7 2.67005 11.63 2.77005C9.83 2.94005 8.25 4.78005 8.25 6.70005V7.67005C8.25 8.08005 7.91 8.42005 7.5 8.42005C7.09 8.42005 6.75 8.08005 6.75 7.67005V6.69005C6.75 4.00005 8.92 1.52005 11.49 1.27005C12.99 1.13005 14.43 1.60005 15.53 2.61005C16.62 3.60005 17.25 5.02005 17.25 6.50005V7.88005C17.25 8.29005 16.91 8.63005 16.5 8.63005Z" />
        </svg>
</span>

        {{$t('newOrders')}}</h2>
      <div style="height: 300px">
        <div style="height: 300px">
          <a-card v-if="stats"   :body-style="{'padding':'8px'}" >
            <a-empty v-if="stats.lastOrders.length === 0" />
            <div style="margin-top:4px;display: flex;flex-direction: row;justify-content: space-between"  v-for="order in stats.lastOrders">
              <span style="margin-right: 3px">{{parseDate(order.created)}}</span><span style="font-weight: 500">{{(order.amount/100).toFixed(2) + ' ' + $store.configStore.user.config.currency}}</span>
            </div>
          </a-card>

        </div>
      </div>
    </div>
    <div class="column is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5">
        <span class="title-icon image-color">
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M13 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V13C22.75 13.41 22.41 13.75 22 13.75C21.59 13.75 21.25 13.41 21.25 13V9C21.25 4.39 19.61 2.75 15 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H13C13.41 21.25 13.75 21.59 13.75 22C13.75 22.41 13.41 22.75 13 22.75Z" />
          <path d="M7.33009 15.24C7.17009 15.24 7.0101 15.19 6.8701 15.08C6.5401 14.83 6.48012 14.36 6.73012 14.03L9.11009 10.94C9.40009 10.57 9.81011 10.33 10.2801 10.27C10.7501 10.21 11.2101 10.34 11.5801 10.63L13.4101 12.07C13.4801 12.13 13.5501 12.12 13.6001 12.12C13.6401 12.12 13.7101 12.1 13.7701 12.02L16.0801 9.04C16.3301 8.71 16.8001 8.65001 17.1301 8.91001C17.4601 9.16001 17.5201 9.63001 17.2601 9.96001L14.9501 12.94C14.6601 13.31 14.2501 13.55 13.7801 13.6C13.3201 13.66 12.8501 13.53 12.4901 13.24L10.6601 11.8C10.5901 11.74 10.5101 11.74 10.4701 11.75C10.4301 11.75 10.3601 11.77 10.3001 11.85L7.92012 14.94C7.78012 15.14 7.56009 15.24 7.33009 15.24Z" />
          <path d="M20.2605 22.75C19.9105 22.75 19.4604 22.64 18.9304 22.32L18.6804 22.17C18.6104 22.13 18.4004 22.13 18.3304 22.17L18.0804 22.32C16.9304 23.01 16.2005 22.72 15.8805 22.48C15.5505 22.24 15.0404 21.64 15.3404 20.32L15.3905 20.11C15.4105 20.03 15.3504 19.84 15.3004 19.78L14.9505 19.43C14.3605 18.83 14.1304 18.13 14.3304 17.5C14.5304 16.88 15.1205 16.44 15.9505 16.3L16.3304 16.24C16.4004 16.22 16.5404 16.12 16.5804 16.05L16.8604 15.48C17.2504 14.69 17.8505 14.24 18.5105 14.24C19.1705 14.24 19.7705 14.69 20.1605 15.48L20.4405 16.04C20.4805 16.11 20.6205 16.21 20.6905 16.23L21.0705 16.29C21.9005 16.43 22.4905 16.87 22.6905 17.49C22.8905 18.11 22.6705 18.81 22.0705 19.42L21.7205 19.77C21.6705 19.83 21.6105 20.02 21.6305 20.1L21.6804 20.31C21.9804 21.63 21.4705 22.23 21.1405 22.47C20.9605 22.61 20.6705 22.75 20.2605 22.75ZM18.4904 15.75C18.4804 15.76 18.3405 15.86 18.2005 16.15L17.9205 16.72C17.6805 17.21 17.1104 17.63 16.5804 17.72L16.2005 17.78C15.8805 17.83 15.7705 17.94 15.7605 17.96C15.7605 17.98 15.7904 18.14 16.0204 18.37L16.3704 18.72C16.7804 19.14 16.9904 19.86 16.8604 20.43L16.8104 20.64C16.7204 21.03 16.7604 21.2 16.7804 21.26C16.8104 21.24 16.9804 21.22 17.3104 21.02L17.5604 20.87C18.1104 20.54 18.9005 20.54 19.4505 20.87L19.7005 21.02C20.1105 21.27 20.2805 21.24 20.2905 21.24C20.2505 21.24 20.3004 21.04 20.2104 20.64L20.1605 20.43C20.0305 19.85 20.2404 19.14 20.6504 18.72L21.0004 18.37C21.2304 18.14 21.2605 17.98 21.2605 17.95C21.2505 17.93 21.1405 17.83 20.8205 17.77L20.4405 17.71C19.9005 17.62 19.3405 17.2 19.1005 16.71L18.8205 16.15C18.6605 15.85 18.5204 15.76 18.4904 15.75Z" />
        </svg>
        </span>

        {{$t('bestSellingProducts')}}</h2>
      <div style="height: 300px">
        <a-card v-if="stats"  :body-style="{'padding':'8px'}" >
          <a-empty v-if="stats.bestSellingProducts.length === 0" />
          <div v-for="product in stats.bestSellingProducts">
            <span style="font-weight: 500;margin-right: 3px">{{product.sold}}</span><span>{{product.title}}</span>
          </div>
        </a-card>
      </div>
    </div>

    <div class="column  is-4-fullhd is-6-tablet is-12-mobile">
      <h2 class="title is-5">
          <span class="title-icon image-color">
        <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
          <path d="M3.93012 16.63C3.74012 16.63 3.55012 16.56 3.40012 16.41C3.11012 16.12 3.11012 15.64 3.40012 15.35L15.3501 3.4C15.6401 3.11 16.1201 3.11 16.4101 3.4C16.7001 3.69 16.7001 4.17 16.4101 4.46L4.46012 16.42C4.32012 16.56 4.12012 16.63 3.93012 16.63Z" />
          <path d="M11.1 19.03C10.91 19.03 10.72 18.96 10.57 18.81C10.28 18.52 10.28 18.04 10.57 17.75L11.77 16.55C12.06 16.26 12.54 16.26 12.83 16.55C13.12 16.84 13.12 17.32 12.83 17.61L11.63 18.81C11.49 18.95 11.3 19.03 11.1 19.03Z" />
          <path d="M13.7905 16.34C13.6005 16.34 13.4105 16.27 13.2605 16.12C12.9705 15.83 12.9705 15.35 13.2605 15.06L15.6505 12.67C15.9405 12.38 16.4205 12.38 16.7105 12.67C17.0005 12.96 17.0005 13.44 16.7105 13.73L14.3205 16.12C14.1805 16.26 13.9805 16.34 13.7905 16.34Z" />
          <path d="M11.0999 22.75C10.1199 22.75 9.13991 22.15 7.94991 20.96L3.03991 16.05C0.649914 13.66 0.659914 12.12 3.06991 9.71004L9.70991 3.07004C12.1199 0.660036 13.6599 0.650036 16.0499 3.04004L20.9599 7.95004C23.3499 10.34 23.3399 11.88 20.9299 14.29L14.2899 20.93C13.0799 22.14 12.0899 22.75 11.0999 22.75ZM12.8999 2.75004C12.3799 2.75004 11.7199 3.18004 10.7699 4.13004L4.12991 10.77C3.17991 11.72 2.74991 12.38 2.74991 12.89C2.74991 13.41 3.14991 14.04 4.09991 14.99L9.00991 19.9C9.95991 20.85 10.5799 21.25 11.0999 21.25C11.0999 21.25 11.0999 21.25 11.1099 21.25C11.6299 21.25 12.2799 20.82 13.2299 19.87L19.8699 13.23C20.8199 12.28 21.2499 11.62 21.2499 11.11C21.2499 10.59 20.8499 9.96004 19.8999 9.01004L14.9899 4.10004C14.0499 3.15004 13.4199 2.75004 12.8999 2.75004Z" />
          <path d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22C1.25 21.59 1.59 21.25 2 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z" />
        </svg>
        </span>

        {{$t('paymentOptions')}}</h2>
      <div style="height: 250px">
        <a-card v-if="stats"  :body-style="{'padding':'8px'}" >
          <a-empty v-if="stats.ordersByPaymentType.length === 0" />
          <PieChart  v-if="stats.ordersByPaymentType.length !== 0" id="paymentsChart" :data="stats.ordersByPaymentType"/>
        </a-card>
      </div>
    </div>
  </div>


</template>

<script>
import {get} from "../../helper/api";
import {PeoplesTwo} from "@icon-park/vue-next";
import {LoadingOutlined} from "@ant-design/icons-vue";
import {useMeta} from "vue-meta";

export default {

  data() {
    return {
      items:[],
      activeKey: '0',
      stats:null,
      loading:true
    }
  },
  components:{PeoplesTwo,LoadingOutlined},
  beforeMount() {
    useMeta({
      title: this.$t('home')
    })
  },
  mounted() {

    this.getStats()
  },
  methods:{

    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")
    },
    async getStats(){
      this.stats = (await get('stats')).json
      console.log(this.stats)
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  display: flex
  flex-direction: row
  align-items: center
.title-icon
  padding: 3px
  margin-right: 5px
  height: 30px
  width: 30px
</style>