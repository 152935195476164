export let BASE_URL = import.meta.env.DEV ? import.meta.env.VITE_API_URL:import.meta.env.VITE_API_URL_PROD
export async function get(path,params={}) {

    let paramString='';
    console.log(params)
    if(params){
        // let url = new URL(BASE_URL+path)
        console.log(params)

        // Object.keys(params).map((param)=>{
        //     if(params.hasOwnProperty(param)){
        //         let value = params[param]
        //         url.searchParams.set(param,value)
        //     }
        //
        // })

        paramString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');



    }

    let result = await fetch(BASE_URL + path +'?'+ paramString,{
        headers: getHeader()
    })

    return {
        json: await result.json(),
        result: result
    }
}
export async function post(path,body,useTempToken=false,method='POST') {
    try {
        let result = await fetch(BASE_URL + path,{
            method: method,
            headers:getHeader(useTempToken),
            body: JSON.stringify(body)
        })

        return {
            "json": await result.json(),
            "ok":result.ok
        };
    }catch (e){
        return {
            "json": {},
            "ok":false
        };
    }

}

function getHeader(useTempToken){
    let token = localStorage.getItem(useTempToken ? 'tempToken' : 'token')
    let header
    if(token){
        header = new Headers({
            'Authorization': 'Bearer '+token
        })

    }
    return header
}
