<script setup>


import CrudIndex from '@/components/dashboard/crud/comment/index.vue'
import CrudHeader from '@/components/dashboard/crud/header.vue'




</script>


<template>




  <CrudHeader title="comments"  />

  <CrudIndex/>

</template>


<script>


import {useMeta} from "vue-meta";

export default {

  data(){
    return {
      total:0,
      active:0,
      disable:0,
    }
  },
  async mounted() {
    useMeta({
      title: this.$t('comments')
    })
    // let result = await get('brand/counts')
    // this.total = result.json.total
    // this.active = result.json.active
    // this.disable = result.json.disable
  }

}
</script>
<style lang="sass">
.info-cards
  margin: 0 0 0 14px

</style>