<script setup>


import CrudIndex from '@/components/dashboard/crud/brand/index.vue'
import CrudHeader from '@/components/dashboard/crud/header.vue'
import ProductForm from '@/components/dashboard/forms/brand_form.vue'



</script>


<template>


  <ProductForm/>

  <CrudHeader title="brands"  :openDialog="$store.brandFormStore.openWithEmptyData"/>

  <div class="p-5">
    <b>{{total}}</b>{{  ' '+ $t('results')}}
  </div>
  <CrudIndex/>

</template>


<script>
import {get} from "@/helper/api.js";
import {useMeta} from "vue-meta";

export default {


  data(){
    return {
      total:0,
      active:0,
      disable:0,
    }
  },
  async mounted() {
    useMeta({
      title: this.$t('brands')
    })
    let result = await get('brand/counts')
    this.total = result.json.total
    this.active = result.json.active
    this.disable = result.json.disable
  }

}
</script>
<style lang="sass">
.info-cards
  margin: 0 0 0 14px

</style>