<template>


  <div style="transition: all 0.5s;"
       :style="configStore.menuCollapsed ? ';width: '+(isMobile).toString()+'px':'width: 256px'">
    <div class="logo">


      <Transition name="fade" mode="out-in">
        <img v-if="configStore.showMiniLogo" src="https://cdn.appmee.de/U4t7l1667478892.png">
        <img v-else src="https://cdn.appmee.de/U4t7l1667478892.png">
      </Transition>


    </div>
    <a-menu style="position: sticky;top: 0" :inline-collapsed="configStore.menuCollapsed "
            v-model:selectedKeys="selectedKeys" mode="inline">
      <a-menu-item key="dashboard_home">
        <template #icon>
          <home-filled/>
        </template>
        <router-link :to="{name:'dashboard_home'}" :class="$route.name === 'dashboard_home' ? 'active':''">
          {{ $t('home') }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="dashboard_homeDesign">
        <template #icon>
          <layout-filled/>
        </template>
        <router-link :to="{name:'dashboard_homeDesign'}" :class="$route.name === 'dashboard_homeDesign' ? 'active':''">
          {{ $t('homeDesign') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_slides">
        <template #icon>
          <picture-filled/>
        </template>
        <router-link :to="{name:'dashboard_slides'}" :class="$route.name === 'dashboard_slides' ? 'active':''">
          {{ $t('slides') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_users">
        <template #icon>
          <peoples class="anticon" theme="filled"/>
        </template>
        <router-link :to="{name:'dashboard_users'}" :class="$route.name === 'dashboard_users' ? 'active':''">
          {{ $t('users') }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="dashboard_orders">
        <template #icon>
          <shopping-filled/>
        </template>
        <router-link :to="{name:'dashboard_orders'}" :class="$route.name === 'dashboard_orders' ? 'active':''">
          {{ $t('orders') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_products">
        <template #icon>
          <hdd-filled/>
        </template>
        <router-link :to="{name:'dashboard_products'}" :class="$route.name === 'dashboard_products' ? 'active':''">
          {{ $t('products') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_questions">
        <template #icon>
          <message-filled/>
        </template>
        <router-link :to="{name:'dashboard_questions'}" :class="$route.name === 'dashboard_questions' ? 'active':''">
          {{ $t('questions') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_comments">
        <template #icon>
          <star-filled/>
        </template>
        <router-link :to="{name:'dashboard_comments'}" :class="$route.name === 'dashboard_comments' ? 'active':''">
          {{ $t('comments') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_brands">
        <template #icon>
          <sketch-circle-filled/>
        </template>
        <router-link :to="{name:'dashboard_brands'}" :class="$route.name === 'dashboard_brands' ? 'active':''">
          {{ $t('brands') }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="dashboard_categories">
        <template #icon>
          <appstore-filled/>
        </template>
        <router-link :to="{name:'dashboard_categories'}" :class="$route.name === 'dashboard_home' ? 'active':''">
          {{ $t('categories') }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="dashboard_discounts">
        <template #icon>
          <percentage class="anticon" theme="filled"/>
        </template>
        <router-link :to="{name:'dashboard_discounts'}" :class="$route.name === 'dashboard_discounts' ? 'active':''">
          {{ $t('discounts') }}
        </router-link>
      </a-menu-item>


      <a-menu-item key="dashboard_notifications">
        <template #icon>
          <bell-filled />
        </template>
        <router-link :to="{name:'dashboard_notifications'}" :class="$route.name === 'dashboard_notifications' ? 'active':''">
          {{ $t('notifications') }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="dashboard_settings">
        <template #icon>
          <setting-filled />
        </template>
        <router-link :to="{name:'dashboard_settings'}" :class="$route.name === 'dashboard_settings' ? 'active':''">
          {{ $t('settings') }}
        </router-link>
      </a-menu-item>

      <a-menu-item-group :title="$t('publish')">

        <a-menu-item key="dashboard_mobile_app">
          <template #icon>
            <mobile-filled/>
          </template>
          <router-link :to="{name:'dashboard_app'}" :class="$route.name === 'dashboard_app' ? 'active':''">
            {{ $t('mobile') }}
          </router-link>
        </a-menu-item>
        <a-menu-item key="dashboard_website">
          <template #icon>
            <browser class="anticon" theme="filled"/>
          </template>
          <router-link :to="{name:'dashboard_website'}" :class="$route.name === 'dashboard_website' ? 'active':''">
            Website
          </router-link>
        </a-menu-item>
        <a-menu-item key="dashboard_customDomain">
          <template #icon>
            <icon-w-w-w :style="{ 'fill': $route.name === 'dashboard_customDomain' ? 'green' : ($store.configStore.darkMode ? 'white':'black') }"/>
          </template>
          <router-link :to="{name:'dashboard_customDomain'}" :class="$route.name === 'dashboard_customDomain' ? 'active':''">
            {{ $t('domain') }}
          </router-link>
        </a-menu-item>
      </a-menu-item-group>
      <a-menu-item-group  v-if="$store.configStore.user.id && $store.configStore.user.roles.includes('ROLE_ADMIN')" title="Admin">
        <a-menu-item key="dashboard_merchants">
          <template #icon>
            <peoples theme="filled"/>
          </template>
          <router-link :to="{name:'dashboard_merchants'}" :class="$route.name === 'dashboard_merchants' ? 'active':''">
            {{ $t('merchants') }}
          </router-link>
        </a-menu-item>
        <a-menu-item key="dashboard_payments">
          <template #icon>
            <bank-card-one class="anticon" theme="filled"/>
          </template>
          <router-link :to="{name:'dashboard_payments'}" :class="$route.name === 'dashboard_payments' ? 'active':''">
            {{ $t('payments') }}
          </router-link>
        </a-menu-item> <a-menu-item key="dashboard_appRequests">
          <template #icon>
            <mobile-filled class="anticon" theme="filled"/>
          </template>
          <router-link :to="{name:'dashboard_appRequests'}" :class="$route.name === 'dashboard_appRequests' ? 'active':''">
            {{ $t('appPublish') }}
          </router-link>
        </a-menu-item>

        <a-menu-item key="dashboard_domainRequests">
          <template #icon>
            <mobile-filled class="anticon" theme="filled"/>
          </template>
          <router-link :to="{name:'dashboard_domainRequests'}" :class="$route.name === 'dashboard_domainRequests' ? 'active':''">
            {{ $t('domain') }}
          </router-link>
        </a-menu-item>
      </a-menu-item-group>
    

    </a-menu>
  </div>


</template>

<script>
import {ConfigStore} from "@/stores/config";
import IconWWW from "../icons/IconWWW.vue";
import {
  HomeFilled,
  MobileFilled,
  AppstoreFilled,
  ShoppingFilled,
  HddFilled,
  SettingFilled,
  QuestionCircleFilled,
  PictureFilled,
  TeamOutlined,
  SketchCircleFilled,
  LayoutFilled,
    StarFilled,
    CommentOutlined,
    MessageFilled,
    BellFilled
} from "@ant-design/icons-vue";
import {Peoples,BankCardOne,Browser,Percentage} from '@icon-park/vue-next';


export default {
  name: "left_menu",
  components: {
    HomeFilled,
    BankCardOne,
    MobileFilled,
    AppstoreFilled,
    ShoppingFilled,
    HddFilled,
    SettingFilled,
    QuestionCircleFilled,
    PictureFilled,
    TeamOutlined,
    SketchCircleFilled,
    LayoutFilled,
    StarFilled,
    CommentOutlined,
    MessageFilled,
    Peoples,
    Browser,
    IconWWW,
    Percentage,
    BellFilled
  },
  setup() {
    const configStore = ConfigStore()

    return {configStore}
  },
  data() {
    return {
      selectedKeys: ['dashboard_home'],
      isMobile: false
    }
  },
  mounted() {
    console.log(window.innerWidth)
    this.isMobile = window.innerWidth < 768 ? 0 : 80
  },
  beforeMount() {
    console.log(screen.width)
    this.selectedKeys = [this.$route.name]
  }
}
</script>

<style lang="sass">
.logo
  height: 58px
  display: flex
  border-right: 1px solid #f0f0f0
  flex-direction: row
  justify-content: center
  align-items: center

  img
    width: 140px
    height: auto

.menu-active
  width: 256px

.menu-disabled
  width: 0

  li
    padding: 0 !important

.menu-active, .menu-disabled
  transition: .5s all


.ant-menu-item-icon
  color: grey
.ant-menu-item-selected
  .ant-menu-item-icon
    color: inherit


</style>