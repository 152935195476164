<template>
  <div class="dashboard-navbar white-background">
    <div   @click="$store.configStore.toggleMenu()" class="mobile-menu-icon">
      <MenuOutlined />
    </div>
    <div class="is-flex is-align-items-center">
      <a-dropdown class="account-avatar m-2" v-if="$store.configStore.user.email.length>0">

        <a-avatar v-if="$store.configStore.user.thumbnail==null" style="color: #038100; background-color: rgba(46,206,60,0.29)">
        <span >
           {{$store.configStore.user.email[0].toUpperCase()}}
        </span>

        </a-avatar>
        <div v-else class="m-2" style="height: 32px;width:32px">
          <img referrerpolicy="no-referrer" style="border-radius:50px" :src="$store.configStore.user.thumbnail">
        </div>

        <template #overlay>
          <div class="user-dropdown white-background">
            <a-avatar v-if="$store.configStore.user.thumbnail==null" style="color: #038100; background-color: rgba(46,206,60,0.29)">
              <span >
                 {{$store.configStore.user.email[0].toUpperCase()}}
              </span>

            </a-avatar>
            <div v-else class="m-2" style="height: 32px;width:32px">
              <img referrerpolicy="no-referrer" style="border-radius:50px" :src="$store.configStore.user.thumbnail">
            </div>

            <div class="company-name">{{$store.configStore.user.name + ' ' + $store.configStore.user.lastname}}</div>
            <div  v-if="$store.configStore.user.facebookId == null" class="email mb-1">
              {{$store.configStore.user.email}}
            </div>
            <div class="is-flex is-justify-content-center is-flex-direction-row p-2">
              <div class="plan-name">

                {{$store.configStore.user.package === 'basic' ? 'Basic':$store.configStore.user.package === 'enterprise' ? 'Enterprise':($store.configStore.user.package === 'premium' ? 'Premium':'Test')}}

              </div>
              <div class="mb-1">
                <router-link :to="{name:'packages'}" >
                  <a-button  type="link">{{$store.configStore.user.package === 'premium' ? $t('update'): $t('upgrade')}}</a-button>
                </router-link>
              </div>
            </div>



            <a-button danger block @click="logout"> {{$t('logout')}} </a-button>

          </div>
        </template>
      </a-dropdown>
      <a-dropdown  placement="topRight" :visible="showSettings"  @click="handleSettingsIcon">
        <setting-filled style="font-size: 20px;padding: 8px;"/>
        <template   #overlay >
          <div  class="user-dropdown mr-2 white-background mt-2" >
            <div class="settings-item ">
              <div>
                {{$t('darkMode')}}
              </div>
              <div>
                <a-switch @change="darkModeChanged " v-model:checked="$store.configStore.darkMode" />
              </div>

            </div>
            <div class="settings-item ">
              <div>
                {{$t('language')}}
              </div>
              <div>
                <a-select @change="localeChanged" style="width: 120px"  v-model:value="$i18n.locale"  >
                  <a-select-option  value="de">Deutsche</a-select-option>
                  <a-select-option  value="en">English</a-select-option>
                  <a-select-option  value="tr">Türkçe</a-select-option>

                </a-select>
              </div>

            </div>
          </div>
        </template>


      </a-dropdown>
    </div>

  </div>

</template>
<style lang="sass" scoped>

.settings-item
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

  &:first-child
    margin-bottom: 12px

.plan-name
  border-radius: .3rem
  background-color: #039e0d
  color: white
  font-size: 14px
  padding: 4px 14px
  display: flex
  justify-content: center
  align-items: center
.account-avatar
  &:hover
    cursor: pointer
.dashboard-navbar
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between


.mobile-menu-icon
  margin: 12px
  padding: 8px 12px
  border-radius: 5px
  &:hover
    background-color: #e2e2e2
    cursor: pointer


.user-dropdown
  width: 300px
  padding: 12px

  box-shadow: 0px 0px 3px #d4d4d4
  margin-top: 12px
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .company-name
    font-size: 18px
  .email
    font-weight: 400
  .filter-item
    margin-bottom: 8px
</style>

<script>


import {MenuOutlined,SettingFilled} from "@ant-design/icons-vue";
import {ConfigStore} from "@/stores/config";
import i18n from "../../i18n";


export default {
  name: 'NavBar',
  components:{
    MenuOutlined,
    SettingFilled
  },
  setup(){
    const configStore = ConfigStore()
    return {configStore}
  },

  mounted() {

    let jwt = localStorage.getItem('token');

    console.log(jwt)
    if(jwt == null){
      this.$router.push('/login')
    }else{
      this.$store.configStore.getCurrentUser()

    }
  },
  props: {
    customContent: {
      type: String,
      default: 'default Content',
    },
  },
  data(){
    return {
      showSettings:false,
      user:null
    }
  },
  methods:{
    localeChanged(locale){
      i18n.locale=locale
      this.$store.configStore.language = locale
      localStorage.setItem('locale',locale)

    },
    darkModeChanged(){
      localStorage.setItem('dark',this.$store.configStore.darkMode)
    },
    handleSettingsIcon(e){
      var clicked = e.target;
      this.showSettings = !this.search;
      var that = this;
      window.addEventListener('click',function check(e){
        if (clicked === e.target || e.target.closest('.settings-item')){
          that.showSettings = true;
        }else {
          that.showSettings = false;
          removeEventListener('click',check)
        }
      })
    },
    logout(){
      localStorage.removeItem('token')
      this.$router.push({path:'/login'})
    },

  }

};
</script>