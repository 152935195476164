<template>



    <a-table
        class="p-3"
        :scroll="{ x: 'max-content' }"
        expandRowByClick
        style="width: 100%"
        :row-key="data => data.id"
        :columns="columns"
        :data-source="questions"
        :pagination="pagination"
        :loading="loading"
        @change="tableStateChanged"
    >


      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'star'">
          <star-filled style="color:orange"/>
          <star-filled :style="'color:'+( record.star>1 ?  'orange':'gray' )"/>
          <star-filled :style="'color:'+( record.star>2 ?  'orange':'gray' )"/>
          <star-filled :style="'color:'+( record.star>3 ?  'orange':'gray' )"/>
          <star-filled :style="'color:'+( record.star>4 ?  'orange':'gray' )"/>

        </template>
        <template v-if="column.dataIndex === 'content'">
          <p style="min-width: 250px;max-width: fit-content;">{{record.content}}</p>
        </template>
        <template v-if="column.dataIndex === 'status'">

          <div style="height: 8px;width: 8px;border-radius: 50px;" :style="'background-color:'+(record.answer == null ? 'red':'green')">

          </div>


        </template>
        <template v-if="column.dataIndex === 'product'">

          <a-button :style="{'padding-left':'0'}" @click.stop="showProductStore.showFormModal=true;product=record.product" type="link">
            <template #icon>
              <img :src="'https://cdn.bringsdelivery.de/'+record.product.thumbnail">
            </template>

          </a-button>


        </template>

        <template v-if="column.dataIndex === undefined">

          <a-popconfirm placement="left" :title="$t('deleteConfirm')"
                        @confirm="deleteProduct(record.id)">
            <a-button @click.stop type="text">
              <template #icon>
                <DeleteOutlined/>
              </template>
            </a-button>

          </a-popconfirm>
        </template>
      </template>

    </a-table>

  <show_product_modal :show-product="showProduct" :product="product"/>
</template>
<script>

import {ShowProductStore} from "@/stores/modal";
import SingleItem from "@/components/dashboard/crud/product/single.vue";

import {SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined,CloseOutlined,CheckOutlined,MessageOutlined,SaveOutlined,StarFilled} from "@ant-design/icons-vue";
import {post} from "@/helper/api";
import {get} from "../../../../helper/api";
import Show_product_modal from "../../modals/show_product_modal.vue";



export default {


  components: {SingleItem, SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined,CloseOutlined,CheckOutlined,MessageOutlined,SaveOutlined,Show_product_modal,StarFilled},
  data() {
    return {
      columns: [],
      pagination: {
        pageSize: 20
      },
      searchText: "",
      loadingDelete: false,
      loadingSave: false,
      searchFilter: false,

      loading:false,
      questions:[],
      showProduct:false,
      product:{},
      showProductStore:{}

    }
  },

  mounted() {
    this.showProductStore = ShowProductStore();

    this.columns = [
      {
        title: this.$t('star'),
        dataIndex: 'star',
        width: '110px'
      },

      {
        title: this.$t('product'),
        dataIndex: 'product',
        width: '110px'
      },
      {
        title: this.$t('review'),
        dataIndex: 'content',


      },
      {
        title: '',
        width: '50px'

      },

    ]
    this.getItems()


  },
  methods: {
    async deleteProduct(id) {
      this.loadingDelete = true
      await post('comment/delete', {
        id: id
      })
      this.loadingDelete = false
      await this.getItems()

    },
    // async save(id,answer) {
    //   this.loadingSave = true
    //   await post('comment', {
    //     id: id,
    //     answer:answer
    //   })
    //   this.loadingSave = false
    //   displaySuccessMessage()
    //   await this.getItems()
    //
    // },
    parseDate(dateString) {
      let date = new Date(dateString)
      return (date.getDate()).toString().padStart(2, "0") + '.' + (date.getMonth() + 1).toString().padStart(2, "0") + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0")

    },
    async getItems() {
      this.loading=true
      let result = await get('comment')
      console.log(result)
      this.questions  = result.json.data
      this.pagination.total = result.json.totalItemCount
      this.loading=false
    },
    tableStateChanged(pagination, filters, sorter) {
      console.log(pagination)
      console.log(pagination)
      let filter = {}
      if (pagination) {
        filter['page'] = pagination.current
      }
      if (filters) {
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['status']) {
          filter['status'] = filters['status']
        }
        if (filters['stock']) {
          filter['stock'] = filters['stock']
        }
      }
      if (sorter && sorter.field && sorter.order) {
        filter['sorter'] = sorter.order
      }
      this.tableState = filter
      localStorage.setItem('tableState', JSON.stringify(this.tableState))
      this.getItems()
    },

  }


}
</script>

<style lang="sass">
.ant-table .ant-table-container::before, .ant-table .ant-table-container::after
  width: 0px


</style>