<template>

    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >
      <a-form-item
          :label="$t('title')"
          name="title"
          :rules="[{ required: true }]"
      >
        <a-input v-model:value="formState.title"/>
      </a-form-item>



      <a-form-item name="upload" :label="$t('image')">
        <a-upload
            :customRequest="uploadFiles"
            v-model:file-list="thumbnailState"
            :show-upload-list="false"
            @change="(uploaded)=>{
              log(uploaded)
              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false
                log(this.thumbnailState)
              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.thumbnail = uploaded.file.response.fileName}
            }"
            name="logo"
            list-type="picture-card"
        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>
            <img style="max-width: 102px;max-height:102px" v-if="formState.thumbnail"
                 :src="'https://cdn.bringsdelivery.de/'+formState.thumbnail" alt="avatar"/>
            <div v-else>
              <PlusOutlined></PlusOutlined>
              <div class="ant-upload-text">{{$t('upload')}}</div>
            </div>

          </div>


        </a-upload>
      </a-form-item>


      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>


    </a-form>



</template>

<script>
import {defineComponent, reactive} from "vue";
import {FormModalStoreCategory} from "../../../stores/modal";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {BASE_URL, post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';


export default defineComponent({


  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
  },


  setup() {


    const formModalStore = FormModalStoreCategory()


    const formState = reactive({
      title: '',
      thumbnail: null,
      parent:null
    });

    const {resetFields} = useForm(formState)
    return {formState, formModalStore, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null
    }
  },
  created() {
    console.log(this.$refs.quill)
  },
  mounted() {

    this.$store.categoryStore.$subscribe((mutation, state) => {


      this.formState.title = ''
      this.formState.thumbnail = null
      this.formState.parent = null
    })
    console.log(this.$refs)

    this.formModalStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {
        if (state.data) {

          this.formState.id = state.data.key
          this.formState.title = state.data.title ?? ""
          this.formState.thumbnail = state.data.thumbnail
          this.formState.parent = state.data.parent

        }
      } else {
        this.resetFields()
      }


    })
  },

  methods: {
    uploadFiles({ onSuccess, onError, file }) {
      let token = localStorage.getItem('token')
      const fmData = new FormData();

      fmData.append("image", file);
      fetch('https://api.appmee.de/media/upload',{
        method: 'POST',
        headers:new Headers({
          'Authorization': 'Bearer '+token
        }),
        body: fmData
      }).then(async (response) => {
        onSuccess(await response.json(), file);
      })
          .catch(() => {
            console.log('error');
          })


    },
    log(item) {
      console.log(item)
    },
    test() {
      console.log(this.$refs.quill)
    },
    async onFinish(formState) {
      this.loading = true
      console.log(formState)

      let result = await post('category', formState)
      console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.categoryStore.getItems()



      }
    }
  }


})
</script>

<style lang="sass" scoped>
.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px

  button
    margin-left: 12px

</style>