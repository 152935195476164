<template>


  <div class="mb-5">
    <a-button @click="approveStatus(user.id,true)" :loading="loadingApproved" v-if="!user.approved" type="primary">
      <template #icon>
        <check-outlined/>
      </template>
      {{$t('approveUser')}}
      </a-button>
    <a-button @click="approveStatus(user.id,false)" :loading="loadingApproved" v-else type="danger">
      <template #icon>
        <close-outlined/>
      </template>
      {{$t('cancelUser')}}
      </a-button>

    <a-popconfirm

        placement="left"
        :title="$t('deleteConfirm')"
        @confirm="deleteUser(user.id)">
      <a-button class="ml-2" :loading="loadingDelete" danger>
        <template #icon>
          <DeleteOutlined/>
        </template>
        {{$t('delete')}}
      </a-button>
    </a-popconfirm>
  </div>


<a-spin :spinning="loadingOrderCounts">
  <div v-if="orderCounts" >
    <div class="info">
      <div class="key">
        {{$t('weeklyOrders')}}:
      </div>
      <div class="value">
        {{ (orderCounts.weekOrders[0].sum/100).toFixed(2) + ' ' + $store.configStore.user.config.currency }} ({{orderCounts.weekOrders[0].count}})
      </div>
    </div>
    <div class="info">
      <div class="key">
        {{$t('monthlyOrders')}}:
      </div>
      <div class="value">
        {{ (orderCounts.monthOrders[0].sum/100).toFixed(2) + ' ' + $store.configStore.user.config.currency }} ({{orderCounts.monthOrders[0].count}})
      </div>
    </div><div class="info">
    <div class="key">
      {{$t('totalOrders')}}:
    </div>
    <div class="value">

      {{ (orderCounts.totalOrders[0].sum/100).toFixed(2) + ' ' + $store.configStore.user.config.currency }} ({{orderCounts.totalOrders[0].count}})
    </div>
  </div>
  </div>
</a-spin>
</template>

<script>
import SingleProduct from './single_product.vue'

import {DeleteOutlined, StarOutlined, GoldOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons-vue";
import {post,get} from "../../../../helper/api";


export default {
  name: "OrderDetails",
  components:{SingleProduct,DeleteOutlined,StarOutlined,GoldOutlined,CloseOutlined,
    CheckOutlined,
    },
  props: {
    user: Object,
    getItems:Function
  },
  mounted() {
    this.getOrderCounts()
  },
  data(){
    return {
      loadingStatus:false,
      loadingTable:false,
      loadingPaymentStatus:false,
      loadingDelete:false,
      loadingApproved:false,
      orderCounts:null,
      loadingOrderCounts:false
    }
  },
  methods:{
    async deleteUser(id){
      this.loadingDelete = true
      await post('user/delete',{
        'id':id
      })
      this.getItems()
      this.loadingDelete = false
    },
    async approveStatus(id,approved){
      this.loadingApproved = true
      await post('user/approveStatus',{
        'id':id,
        approved:approved
      })
      this.getItems()
      this.loadingApproved = false
    },
    async getOrderCounts(){
      this.loadingOrderCounts = true
      this.orderCounts = (await get('user/orderCounts',{
        'id':this.user.id
      })).json

      this.loadingOrderCounts = false
    },

  }
}
</script>

<style lang="sass" scoped>
.info
  display: flex
  flex-direction: row
  margin-bottom: 8px
  .value
    font-weight: 500
    margin-left: 4px
</style>