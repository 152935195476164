import { defineStore } from 'pinia'
import {get} from "../helper/api";
import getSymbolFromCurrency from "currency-symbol-map";

export const ConfigStore = defineStore({
  id: 'ConfigStore',
  state: () => ({
    menuCollapsed: window.innerWidth<768,
    signupStep:1,
    iframeUpdate:false,
    domainName:null,
    user:{name:'',email:'',lastname:'',config:{currency:'EUR'}},
    visibleUpgradeModal:false,
    upgradeModelPlan:'premium',
    setupIncluded:false,
    darkMode: localStorage.getItem('dark') === 'true',
    language: localStorage.getItem('locale')??'de',
    successMessage:'Gespeichert',
    errorMessage:"Es ist ein Fehler aufgetreten",
    showMiniLogo:window.innerWidth<768,
    showUpgradeModal(plan){
      this.upgradeModelPlan = plan
      this.visibleUpgradeModal = true
    },
    async toggleMenu() {
      this.menuCollapsed = !this.menuCollapsed ;

      this.showMiniLogo = this.menuCollapsed
      console.log(this.showMiniLogo)
    },
    async getCurrentUser(){

      let result = await get('user')
      console.log(result.result.status)
      if(result.result.status===401){
        localStorage.removeItem('token')
        this.$router.push({path:'/login'})
      }
      this.user = result.json.data
      console.log(this.user)
      if(this.user.config == null || this.user.config == true ){
        this.user.config = {currency:'EUR'}
    
      }else{
        this.calculateDomainName()
        this.user.config.currency = getSymbolFromCurrency(this.user.config.currency)
        console.log(this.user.config)
      }
    

    },
    calculateDomainName(){
      let isDomain = new RegExp('^([a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,}$')
      if(this.user.config.domain != null && isDomain.test(this.user.config.domain)){
        this.domainName = this.user.config.domain
      }
    }
  })
})
