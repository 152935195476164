<template>
  <a-modal :footer="null" v-model:visible="formModalStore.showFormModal" >
    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish(formState)"
    >
      <a-form-item
          :label="$t('brand')"
          name="name"
          :rules="[{ required: true}]"
      >
        <a-input v-model:value="formState.name"/>
      </a-form-item>



      <a-form-item name="upload" :label="$t('image')">
        <a-upload
            v-model:file-list="thumbnailState"
            :show-upload-list="false"
            @change="(uploaded)=>{
              log(uploaded)
              if(uploaded.file.status==='done'){
                this.thumbnailLoading=false
                log(this.thumbnailState)
              }
              if(uploaded.file.status!=='done'){
                this.thumbnailLoading=true
              }
              if(uploaded.file.response){formState.thumbnail = uploaded.file.response.fileName}
            }"
            name="logo"
            action="https://api.appmee.de/media/upload"
            list-type="picture-card"
        >
          <LoadingOutlined v-if="thumbnailLoading"></LoadingOutlined>
          <div v-else>
            <img style="max-width: 102px;max-height:102px" v-if="formState.thumbnail"
                 :src="'https://cdn.bringsdelivery.de/'+formState.thumbnail" alt="avatar"/>
            <div v-else>
              <PlusOutlined></PlusOutlined>
              <div class="ant-upload-text">{{$t('upload')}}</div>
            </div>

          </div>


        </a-upload>
      </a-form-item>


      <a-button key="submit" type="primary" html-type="submit" :loading="loading">{{ $t('save') }}</a-button>


    </a-form>
  </a-modal>



</template>

<script>
import {defineComponent, reactive} from "vue";
import {FormModalStoreBrand} from "../../../stores/modal";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {post} from "../../../helper/api";

import {displayErrorMessage, displaySuccessMessage} from "../../../helper/messages";
import {useForm} from "ant-design-vue/es/form";


import {UploadOutlined, InboxOutlined, PlusOutlined, LoadingOutlined} from '@ant-design/icons-vue';


export default defineComponent({


  components: {
    QuillEditor,
    UploadOutlined,
    InboxOutlined,
    PlusOutlined,
    LoadingOutlined,
  },


  setup() {


    const formModalStore = FormModalStoreBrand()


    const formState = reactive({
      name: '',
      thumbnail: null,
      parent:null
    });

    const {resetFields} = useForm(formState)
    return {formState, formModalStore, resetFields}
  },

  data() {
    return {
      loading: false,
      closeModalAfterSave: true,
      clearDataAfterSave: true,
      thumbnailLoading: false,
      thumbnailState: null,
      quill: null
    }
  },
  created() {
    console.log(this.$refs.quill)
  },
  mounted() {

    this.$store.categoryStore.$subscribe((mutation, state) => {


      this.formState.name = ''
      this.formState.thumbnail = null
      this.formState.parent = null
    })
    console.log(this.$refs)

    this.formModalStore.$subscribe((mutation, state) => {

      if (state.showFormModal) {
        if (state.data) {

          this.formState.id = state.data.id
          this.formState.name = state.data.name ?? ""
          this.formState.thumbnail = state.data.thumbnail


        }
      } else {
        this.resetFields()
      }


    })
  },

  methods: {
    log(item) {
      console.log(item)
    },

    async onFinish(formState) {
      this.loading = true
      console.log(formState)

      let result = await post('brand', formState)
      console.log(result)
      if (!result.ok) {
        displayErrorMessage()
        this.loading = false
        return;
      }
      console.log(result.json)
      if (result.json.status) {
        this.loading = false
        displaySuccessMessage()

        this.$store.brandStore.getItems()



      }
    }
  }


})
</script>

<style lang="sass" scoped>
.ant-modal-body
  padding-top: 10px

.modal-footer
  display: flex
  flex-direction: row
  justify-content: end
  position: sticky
  bottom: 0
  padding: 12px
  background-color: white

  button
    margin-left: 12px

</style>