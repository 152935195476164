<script setup>


import CrudIndex from '@/components/dashboard/crud/question/index.vue'
import CrudHeader from '@/components/dashboard/crud/header.vue'




</script>


<template>




  <CrudHeader title="questions" />
  <CrudIndex/>



</template>


<script>


import {useMeta} from "vue-meta";

export default {

  data(){
    return {
      total:0,
      active:0,
      disable:0,
      activeKey:"all",
      questions:[]
    }
  },
  async mounted() {
    useMeta({
      title: this.$t('questions')
    })
  //   let result = await get('brand/counts')
  //   this.total = result.json.total
  //   this.active = result.json.active
  //   this.disable = result.json.disable
  }

}
</script>
<style lang="sass">
.info-cards
  margin: 0 0 0 14px

</style>